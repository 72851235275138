import "./style.scss";
import { serviceDetails } from "../../../data/serviceDetails.js";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const Services = () => {
  // for translation
  const { t } = useTranslation(["home", "serviceDetails"]);

  return (
    <div className="Services container ">
      <div className="Services_heading" data-aos="fade-up">
        <h2 className="Services_heading_text">{t("services_heading")}</h2>
        <div className="Services_heading_underline"></div>
      </div>
      <h3 className="Services_text" data-aos="fade-up">
        {t("services_description")}
      </h3>
      <div className="Services_servicesContainer">
        {serviceDetails.map((item, index) => {
          return (
            <Service
              title={t("serviceDetails:" + item.title)}
              icon={item.icon}
              key={index}
              path={item.title}
            />
          );
        })}
      </div>
    </div>
  );
};

// Common component for service details
const Service = ({ title, icon, path }) => {
  return (
    <Link
      to={`/our-services/${path}`}
      className="Service"
      data-aos="zoom-in-up"
    >
      <div className="Service_icon">
        <img src={icon} alt={title} />
      </div>
      <h4 className="Service_title">{title}</h4>
    </Link>
  );
};
