export const serviceDetails_AR = {
  golden_visa: `الإقامة الذهبية`,
  golden_visa_shortDescription: `ما هي تأشيرة أبوظبي الذهبية؟

إقامة أبوظبي الذهبية هي برنامج إقامة تقدمه حكومة أبوظبي، بالإمارات العربية المتحدة (أع م). وقد تم تصميم هذا البرنامج لاجتذاب المستثمرين الأجانب ورواد الأعمال والمهنيين ذوي المهارات العالية إلى الإمارة من خلال منحهم وضع الإقامة ومجموعة من المزايا الأخرى. `,
  golden_visa_longDescription: `ومن خلال إقامة أبوظبي الذهبية، يمكن للأفراد المؤهلين وعائلاتهم العيش والعمل في دولة الإمارات العربية المتحدة لمدة تصل إلى عشر سنوات، مع إمكانية التجديد. 

كما توفر الإقامة أيضًا مزايا مثل الوصول إلى الرعاية الصحية والتعليم وفرص الاستثمار. ويعد البرنامج جزءًا من الجهود الكبيرة التي تبذلها حكومة أبوظبي لتنويع الاقتصاد، وتعزيز التنافسية، واجتذاب المواهب الدولية إلى الإمارة. 

الحصول على الحصول على الإقامة الذهبية في أبوظبي قد يكون عملية معقدة، ولكن بمساعدة منصة مثل آمر، يمكن جعلها أكثر سهولة ويسرًا بكثير بالنسبة للأفراد المؤهلين.`,

  translation: `الترجمة`,
  translation_shortDescription: `ما هي الترجمة القانونية؟
الترجمة القانونية هي ترجمة النصوص في مجال القانون من اللغات الأجنبية إلى اللغة العربية والعكس صحيح.`,
  translation_longDescription: `لماذا هناك حاجة للترجمة القانونية في دولة الإمارات العربية المتحدة؟
لأن اللغة الرسمية في الإمارات هي اللغة العربية ولإتمام أي معاملات رسمية قانونية يجب أن تكون المستندات المطلوبة مترجمة قانونيا من اللغة الأصلية إلى اللغة العربية ومن ثم يتم المصادقة على هذه الترجمة من قبل المختصين قانونياً، ويتم إصدار الحكم والقرار القانوني بأن هذه الترجمة مقبولة للنصوص القانونية ويصح العمل بها.

يجب أن يتم ترجمة المستند في جهات  معتمدة لدى الدوائر الحكومية والوزارات والمحاكم. 

بعض من أنواع المستندات التي تحتاج إلى الترجمة بناءًا على الخدمات والاحتياجات التي تخدمها الشهادات (الشهادات العلمية، الزواج ، الولادة، الوفاة، إلخ) والوكالات.

توفر آمر خدمة الترجمة القانونية، حيث تتم الترجمة من قبل مترجمين متخصصين قانونًا معتمدين من الجهات الحكومية في دولة الإمارات العربية المتحدة، وتبدأ الخدمة فور استلام المستندات ، ويتم تتبع المعاملة من قبلنا.
`,

  attestations: `التصديقات`,
  attestations_shortDescription: `ما هو عملية تصديق الأوراق الرسمية والشهادات؟


التصديق هو إجراء لتأكيد صلاحية وصحة الختم والتوقيع على المستندات الصادرة في دولة الإمارات العربية المتحدة أو في الخارج.`,
  attestations_longDescription: `أهمية عملية التصديق في دولة الإمارات العربية المتحدة؟
إن عملية التصديق على الشهادات الجامعية والوثائق الشخصية أو التجارية التي تم الحصول عليها أو القيام بها خارج دولة الإمارات العربية المتحدة يعتبر أمراً هاماً لكي تستطيع استخدامها بشكل قانوني في الإمارات، ولكي تتمكن من التقديم على وظيفة أو استخدامها لأغراض قانونية أخرى.


ونظراً لأن التصديق على الوثائق هو أمر مهم للغاية لكي تكون الوثيقة رسمية وقانونية وخالية من أي إخطاء، فإننا في آمر نلتزم بالتعاون مع الجهات المختصة لجعل عملية التصديق على الوثائق أسهل وأسرع.


وتنقسم الوثائق التي تحتاج إلى تصديق إلى نوعين وهما:
المستندات التجارية على سبيل المثال لا الحصر (عقود الشركات – الوكالات – التراخيص – عقود الشراكة – عقود التأسيس- عقد أو اتفاقية - سجل تجاري - تغيير اسم الشركة - تسجيل العلامة التجارية - شهادة تأمين الشركات - شهادة ضريبة الشركات - الرخصة التجارية - تسجيل براءة الاختراع أو التنازل – توكيل عام أو أي مستند آخر ذي طبيعة تجارية) التي يجب أن تكون موثقة لدى سفارة الإمارات العربية المتحدة من ذلك البلد، وبعد ذلك يمكن التصديق عليه في وزارة الخارجية الإماراتية.


مستندات أحوال شخصية  مثل (عقود الزواج – الشهادات الجامعية – شهادات الميلاد – شهادات الوفاة - التقارير الطبية - شهادة حسن السيرة والسلوك - الشهادات الصادرة عن المحكمة - التوكيل الشخصي – شهادة لمن يهمه الامر - عقد عمل - عرض التوظيف - كشوف الحسابات البنكية - شهادة ضريبة شخصية وغيرها.

متطلبات خاصة: 
تصديق سفارة دولة الإمارات العربية المتحدة من الدولة الصادرة منها الشهادة/ أو تصديق سفارة الدولة الصادرة منها الشهادة في دولة الإمارات العربية المتحدة.
تصديق وزارة خارجية الدولة الصادر منها المستند (بلد المنشأ)
`,

  commercial_license: `الرخص التجارية`,
  commercial_license_shortDescription: `تسعى دولة الإمارات العربية المتحدة  إلى تلبية احتياجات المواطن والمقيم على اختلافها وتسهيل العديد من العمليات المتعلقة بتنمية أعمال الأفراد، وتعمل على إدارة المبادرات الاقتصادية من أجل تعزيز اقتصاد العاصمة أبوظبي من خلال دعم الأنشطة التجارية المتنوعة وتوفير مجموعة كبيرة من الخدمات التي تُعنى بالرخص الاقتصادية. `,
  commercial_license_longDescription: `ولعل خدمات دائرة التنمية الاقتصادية في أبوظبي من أهم المؤسسات التي قد يحتاجها السكان لذلك قامت آمر بتوفير خدمات تقديم للراغبين في العمل وإصدار تصريح عمل مستقل في دولة الإمارات بشكل عام وأبو ظبي بشكل خاص وهي رخصة المهن الحرة، مما يتيح للأفراد القيام بأعمال حرة مختلفة في أبوظبي، دون قيود الشركات التي تطلب من الموظفين اتخاذ تدابير محددة، كما يمكن تقديم طلب لتجديد الرخصة التجارية لإمارة أبوظبي وحجز اسم تجاري بطريقة سهلة ومريحة من خلال تطبيق آمر.


كذلك يتوفر في آمر خدمة التقديم على رخصة عمل حر في الإمارات (رخصة التاجر الإلكتروني) بكل سهولة وسرعة وهي من الخدمات المطلوبة كثيرا في الآونة الأخيرة والتي تواكب التطور التكنولوجي في دولة الإمارات العربية المتحدة، حيث قامت العديد من الشركات بتحويل أعمالها إلى النطاق الإلكتروني، وذلك لما توفره من مزايا وفوائد والتي تعمل على تحويل طبيعة العمل من ملموس إلى مرئي واستقطاب أكبر عدد من العملاء والزبائن. كما أنها تتيح للأفراد من المواطنين والمقيمين الاستفادة من مواهبهم وتسخيرها في سوق العمل.


من أهم ميزات رخصة عمل حر في الامارات أنها لا تتطلب توافر مقر فعلي للشركة، حيث يمكن القيام بالأعمال الحرة في أي مكان حسب طبيعة العمل. كما تمنح الحرية لأصحاب الأعمال الحرة، إذ يمكنهم العمل في أي وقت أيضاً حسبما يلائمهم. كذلك لا يحتاج حامل رخصة المهن الحرة في أبو ظبي إلى شريك مواطن أو وكيل خدمات محلي.
`,

  municipalities_and_transport: `البلديات والنقل`,
  municipalities_and_transport_shortDescription: `أحد الخدمات والإجراءات القانونية للمعاملات العقارية التي قامت آمر بتسهيلها على العملاء من أجل توفير الجهد والمال هي تجديد عقد الايجار.`,
  municipalities_and_transport_longDescription: `يضمن عقد الإيجار الانفتاح والشفافية بين المالك والمستأجر، ودمج كافة عقود الإيجار في النظام القانوني، ومراجعة العقود بسهولة في حال حدوث خلاف بين الطرفين، وضمان حقوقهما إذا وقع أي نزاع.

يمكن للمؤجر التقديم على الخدمة عند الحاجة لتجديد عقد إيجار مسجل مسبقاً والحصول على العقد مباشرة من النظام أو من طرف المستأجر شرط الحصول على الموافقة من المؤجر بعد تقديم الطلب من المستأجر للحصول على العقد من النظام.`,

  delivery_services: `خدمات التوصيل`,
  delivery_services_shortDescription: `يوفر تطبيق آمر خدمة التوصيل من استلام وتسليم الوثائق المنجزة إلى الشركات والأفراد على حد سواء، حيث نسعى إلى تقديم أفضل الحلول والطرق لإنجاز وتخليص واستلام وإيصال كافة الوثائق الرسمية بالنيابة عن العملاء مما يسهم في توفير الوقت باستخدام إجراءٍ سهلٍ يسمح للعملاء بتقديم وثائقهم حيثما وجدوا.`,
  delivery_services_longDescription: `وتركز الشركة على ضمان راحة العملاء وسعادتهم، و توفير الوقت والجهد، مع ضمان سرية معلومات العملاء باستخدام النظام الآمن.
  `,

  human_resources: `الموارد البشرية`,
  human_resources_shortDescription: `وفقا لقانون دولة الإمارات العربية المتحدة بشأن تنظيم علاقات العمل،المعروف بـ "قانون العمل الإماراتي" تعد الإقامة شرطاً أساسياً لجميع الوافدين الذين يرغبون في العمل أو العيش في دولة الإمارات العربية المتحدة، ولا يجوز ممارسة العمل في الدولة إلا بعد الحصول على تصريح عمل كما أنها تعد من متطلبات الحصول على العديد من الخدمات الحيوية، كفتح حساب مصرفي أو الحصول على رخصة قيادة وغيرها. ويتم الحصول على تصريح و إقامة العمل من وزارة الموارد البشرية والتوطين.`,
  human_resources_longDescription: `وتماشيًا مع استراتيجية دولة الإمارات العربية المتحدة للخدمات الحكومية الرقمية، وسياسة المنصة الرقمية الموحدة، لتقديم خدمات رقمية متطورة وفي أي مكان، توفر منصة آمر خدمات الموارد البشرية من إدارة ونقل المستندات في إمارة أبوظبي.

كما نوفر خدمة استخراج عقد عمل الموظف وفقا لأحد أنماط العمل المعتمدة، كذلك توفر باقة الإقامة الإماراتية (خارج وداخل الدولة)، وفتح ملف الشركة لتسهيل إجراءات العمل والعمال.

تطبق الشروط والأحكام
`,

  id_and_immigration: `الجوازات والهوية`,
  id_and_immigration_shortDescription: ``,
  id_and_immigration_longDescription: `تقدم آمر مجموعة متنوعة من الخدمات الإلكترونية المتكاملة المتعلقة بالجوازات والهوية في تطبيق آمر لتسهيل وتسريع إنجاز معاملات التأشيرات والإقامة للقطاعين الحكومي والخاص ، بالإضافة إلى إصدار الهوية الإماراتية وتسهيلها للأفراد والشركات. في أبوظبي لتوفير الوقت والجهد للجميع.
`,

  international_visas: `التأشيرات الدولية`,
  international_visas_shortDescription: `تأشيرة سياحية متعددة الدخول
أتاحت منصة آمر عبر نظام خدماتها الذكية للجمهورتقديم طلب الحصول على تأشيرة سياحية متعددة الدخول لمجموعة عائلية مع الأطفال (ما دون 18 عاماً) ، لمدة خمس سنوات ويتم إصدارها لجميع الجنسيات من دون كفيل.`,
  international_visas_longDescription: `وهذه التأشيرة تسمح للمستفيد دخول دولة الإمارات لأكثر من مرة دون الحاجة إلى ضامن أو مضيف، ويظل داخل الدولة لمدة 90 يوم في كل زيارة، ويمكن تمديد الزيارة لمدة 90 يوم أخرى، بحيث لا تتعدى مجمل زيارته 180 يوماً متصلة في السنة على شرط دخول الدولة خلال 60 يوماً من تاريخ إصدار تصريح الدخول لتجنب إلغائه.

ويستطيع المتعامل التقديم على التأشيرة عبر تطبيق آمر والحصول عليها إلكترونياً خلال يوم عمل من التقديم عليها واستيفاء المتطلبات.

للحصول على هذه التأشيرة، يجب أن يكون لدى المتقدم:
رصيد بنكي بقيمة 4,000 دولار أو ما يعادلها من العملات الأجنبية خلال آخر 6 أشهر
وثيقة تأمين صحي سارية في الدولة
تذكرة طيران ذهاب وعودة من الإمارات
إثبات محل الإقامة (فندق/عنوان سكن) في دولة الإمارات.
`,

  domestic_workers: `العمالة المساعدة`,
  domestic_workers_shortDescription: `إذا كنت توظف عمالة مساعدة للعمل في دولة الامارات العربية المتحدة ، فيجب التقدم بطلب الحصول على تصريح العمل حسب القانون الإماراتي بشأن عمال الخدمة المساعدة الذي يضمن وعي ومعرفة عمال الخدمات المساعدة بشروط العقد، وطبيعة العمل، ومكان العمل، والأجر، وفترة الراحة اليومية والأسبوعية على النحو الذي تحدده اللوائح التنفيذية، ويشترط القانون أن يتم ذلك قبل استقدام العامل ومغادرته موطنه`,
  domestic_workers_longDescription: `يوفر تطبيق آمر خدمة توفير تصريح عمل للعمالة المنزلية لمواطني دولة الإمارات العربية المتحدة ودول مجلس التعاون الخليجي والمقيمين من خلال تسهيل الطلب إلكترونيًا والتأكد من متابعته وتخليصه.

تطبق الأحكام القانونية المنظمة لتوظيف عمال الخدمة المساعدة في دولة الإمارات العربية.
`,

  judicial_department: `دائرة القضاء`,
  judicial_department_shortDescription: `إن العدل هو أساس الحكم وإن تقدم المجتمعات لا يتحقق إلا بالعدالة والمساواة وتكافؤ الفرص، ودور دائرة القضاء هو سيادة القانون من خلال تحقيق العدالة وصيانة الحقوق والحريات وأمن المجتمع.`,
  judicial_department_longDescription: `نظراً للتطور السريع الذي تمر به إمارة أبوظبي وتدفق الاستثمارات الاقليمية والعالمية إليها، أصبحت الخدمات القضائية قائمة على نظام رقمي عالي الجودة لضمان خدمة العملاء في تنفيذ الإجراءات القضائية.


آمر هي منصة رقمية مصممة لتسهيل خدمة العملاء من خلال تسهيل تقديم الخدمات القضائية المختلفة لمحكمة العاصمة أبوظبي (أبوظبي والعين والمنطقة الغربية) وضمان خدمة عالية الجودة والفعالية خالية من المتاعب ، فقط على العميل تحميل المستندات في التطبيق ثم التكفل بالباقي من قبلنا.`,
};
