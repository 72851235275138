export const serviceDetails = [
  {
    title: "golden_visa",
    icon: require("assets/icons/pages/home/services/golden-visa.png"),
    shortDescription: "golden_visa_shortDescription",
    longDescription: "golden_visa_longDescription",
  },
  {
    title: "translation",
    icon: require("assets/icons/pages/home/services/translation.png"),
    shortDescription: "translation_shortDescription",
    longDescription: "translation_longDescription",
  },
  {
    title: "attestations",
    icon: require("assets/icons/pages/home/services/attestation.png"),
    shortDescription: "attestations_shortDescription",
    longDescription: "attestations_longDescription",
  },
  {
    title: "commercial_license",
    icon: require("assets/icons/pages/home/services/commercial-licenses.png"),
    shortDescription: "commercial_license_shortDescription",
    longDescription: "commercial_license_longDescription",
  },
  {
    title: "municipalities_and_transport",
    icon: require("assets/icons/pages/home/services/municipality.png"),
    shortDescription: "municipalities_and_transport_shortDescription",
    longDescription: "municipalities_and_transport_longDescription",
  },
  {
    title: "delivery_services",
    icon: require("assets/icons/pages/home/services/delivery.png"),
    shortDescription: "delivery_services_shortDescription",
    longDescription: "delivery_services_longDescription",
  },
  {
    title: "human_resources",
    icon: require("assets/icons/pages/home/services/human-resources.png"),
    shortDescription: "human_resources_shortDescription",
    longDescription: "human_resources_longDescription",
  },
  {
    title: "id_and_immigration",
    icon: require("assets/icons/pages/home/services/immigration-&-id.png"),
    shortDescription: "id_and_immigration_shortDescription",
    longDescription: "id_and_immigration_longDescription",
  },
  {
    title: "international_visas",
    icon: require("assets/icons/pages/home/services/international-visas.png"),
    shortDescription: "international_visas_shortDescription",
    longDescription: "international_visas_longDescription",
  },
  {
    title: "domestic_workers",
    icon: require("assets/icons/pages/home/services/domestic-services.png"),
    shortDescription: "domestic_workers_shortDescription",
    longDescription: "domestic_workers_longDescription",
  },
  {
    title: "judicial_department",
    icon: require("assets/icons/pages/home/services/judicial-department.png"),
    shortDescription: "judicial_department_shortDescription",
    longDescription: "judicial_department_longDescription",
  },
];
