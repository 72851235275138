import { useTranslation } from "react-i18next";
import { iconsPath } from "utils/iconsPath";
import { imagesPath } from "utils/imagesPath";
import "./style.scss";

export const Content = () => {
  // for translation
  const { t } = useTranslation("ourPartners");

  return (
    <div
      className="Content"
      style={{ backgroundImage: `url(${imagesPath.BACKGROUND_PATTERN})` }}
    >
      <div className="Content_header">
        <img
          src={iconsPath.EMIRATES_POST}
          alt="Emirates Post"
          data-aos="zoom-in"
        />
      </div>
      <div className="Content_info container" data-aos="fade-up">
        <div className="Content_info_heading" data-aos="fade-up">
          <h2 className="Content_info_heading_text">
            {t("ourPartners_heading")} <br /> {t("ourPartners_emiratesPost")}
          </h2>
          <div className="Content_info_heading_underline"></div>
        </div>
        <h3 className="Content_info_text" data-aos="fade-up">
          {t("ourPartners_description")}
        </h3>
      </div>
    </div>
  );
};
