export const privacyPolicySlideDetails = [
  {
    icon: require("assets/icons/pages/privacyPolicy/slide1.png"),
    heading: "privacyPolicy_slide1_heading",
    shortDescription: "privacyPolicy_slide1_shortDescription",
    longDescription: "privacyPolicy_slide1_longDescription",
  },
  {
    icon: require("assets/icons/pages/privacyPolicy/slide2.png"),
    heading: "privacyPolicy_slide2_heading",
    shortDescription: "privacyPolicy_slide2_shortDescription",
    longDescription: "privacyPolicy_slide2_longDescription",
  },
  {
    icon: require("assets/icons/pages/privacyPolicy/slide3.png"),
    heading: "privacyPolicy_slide3_heading",
    shortDescription: "privacyPolicy_slide3_shortDescription",
    longDescription: "privacyPolicy_slide3_longDescription",
  },
  {
    icon: require("assets/icons/pages/privacyPolicy/slide4.png"),
    heading: "privacyPolicy_slide4_heading",
    shortDescription: "privacyPolicy_slide4_shortDescription",
    longDescription: "privacyPolicy_slide4_longDescription",
  },
  {
    icon: require("assets/icons/pages/privacyPolicy/slide5.png"),
    heading: "privacyPolicy_slide5_heading",
    shortDescription: "privacyPolicy_slide5_shortDescription",
    longDescription: "privacyPolicy_slide5_longDescription",
  },
  {
    icon: require("assets/icons/pages/privacyPolicy/slide6.png"),
    heading: "privacyPolicy_slide6_heading",
    shortDescription: "privacyPolicy_slide6_shortDescription",
    longDescription: "privacyPolicy_slide6_longDescription",
  },
  {
    icon: require("assets/icons/pages/privacyPolicy/slide7.png"),
    heading: "privacyPolicy_slide7_heading",
    shortDescription: "privacyPolicy_slide7_shortDescription",
    longDescription: "privacyPolicy_slide7_longDescription",
  },
  {
    icon: require("assets/icons/pages/privacyPolicy/slide8.png"),
    heading: "privacyPolicy_slide8_heading",
    shortDescription: "privacyPolicy_slide8_shortDescription",
    longDescription: "privacyPolicy_slide8_longDescription",
  },
  {
    icon: require("assets/icons/pages/privacyPolicy/slide9.png"),
    heading: "privacyPolicy_slide9_heading",
    shortDescription: "privacyPolicy_slide9_shortDescription",
    longDescription: "privacyPolicy_slide9_longDescription",
  },
  {
    icon: require("assets/icons/pages/privacyPolicy/slide10.png"),
    heading: "privacyPolicy_slide10_heading",
    shortDescription: "privacyPolicy_slide10_shortDescription",
    longDescription: "privacyPolicy_slide10_longDescription",
  },
  {
    icon: require("assets/icons/pages/privacyPolicy/slide11.png"),
    heading: "privacyPolicy_slide11_heading",
    shortDescription: "privacyPolicy_slide11_shortDescription",
    longDescription: "privacyPolicy_slide11_longDescription",
  },
];
