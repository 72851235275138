import i18n from "i18next";
import { aboutUs_AR } from "locales/ar/aboutUs.js";
import { ourCompanies_AR } from "locales/ar/ourCompanies.js";
import { ourPartners_AR } from "locales/ar/ourPartners.js";
import { aboutUs_EN } from "locales/en/aboutUs.js";
import { ourCompanies_EN } from "locales/en/ourCompanies.js";
import { ourPartners_EN } from "locales/en/ourPartners.js";
import { initReactI18next } from "react-i18next";
import { footer_AR } from "./locales/ar/footer.js";
import { home_AR } from "./locales/ar/home.js";
import { navbar_AR } from "./locales/ar/navbar.js";
import { footer_EN } from "./locales/en/footer.js";
import { home_EN } from "./locales/en/home.js";
import { navbar_EN } from "./locales/en/navbar.js";
import { contactUs_EN } from "./locales/en/contactUs.js";
import { contactUs_AR } from "./locales/ar/contactUs.js";
import { commonContactUs_EN } from "locales/en/commonContactUs.js";
import { commonContactUs_AR } from "locales/ar/commonContactUs.js";
import { our_services_EN } from "locales/en/ourServices.js";
import { our_services_AR } from "locales/ar/ourServices.js";
import { highlightSlideDetails_EN } from "locales/en/highlightSlideDetails.js";
import { highlightSlideDetails_AR } from "locales/ar/highlightSlideDetails.js";
import { companyDetails_EN } from "locales/en/companyDetails.js";
import { companyDetails_AR } from "locales/ar/companyDetails.js";
import { serviceDetails_AR } from "locales/ar/serviceDetails.js";
import { serviceDetails_EN } from "locales/en/serviceDetails.js";
import { testimonialDetails_AR } from "locales/ar/testimonialDetails.js";
import { testimonialDetails_EN } from "locales/en/testimonialDetails.js";
import { cookiesPolicy_EN } from "locales/en/cookiesPolicy.js";
import { cookiesPolicy_AR } from "locales/ar/cookiesPolicy.js";
import { disclaimer_EN } from "locales/en/disclaimer.js";
import { disclaimer_AR } from "locales/ar/disclaimer.js";
import { refundPolicy_EN } from "locales/en/refundPolicy.js";
import { refundPolicy_AR } from "locales/ar/refundPolicy.js";
import { refundPolicyProceduresDetails_EN } from "locales/en/refundPolicyProceduresDetails.js";
import { refundPolicyProceduresDetails_AR } from "locales/ar/refundPolicyProceduresDetails.js";
import { privacyPolicy_AR } from "locales/ar/privacyPolicy.js";
import { privacyPolicy_EN } from "locales/en/privacyPolicy.js";
import { cookiesSlideDetails_EN } from "locales/en/cookiesSlideDetails.js";
import { cookiesSlideDetails_AR } from "locales/ar/cookiesSlideDetails.js";
import { privacyPolicySlideDetails_EN } from "locales/en/privacyPolicySlideDetails.js";
import { privacyPolicySlideDetails_AR } from "locales/ar/privacyPolicySlideDetails.js";
import { goldenVisa_EN } from "locales/en/goldenVisa.js";
import { goldenVisa_AR } from "locales/ar/goldenVisa.js";

// Creating object with the variables of imported translation files
const resources = {
  en: {
    navbar: navbar_EN,
    footer: footer_EN,
    home: home_EN,
    aboutUs: aboutUs_EN,
    ourPartners: ourPartners_EN,
    ourCompanies: ourCompanies_EN,
    contactUs: contactUs_EN,
    commonContactUs: commonContactUs_EN,
    ourServices: our_services_EN,
    highlightSlideDetails: highlightSlideDetails_EN,
    companyDetails: companyDetails_EN,
    serviceDetails: serviceDetails_EN,
    testimonialDetails: testimonialDetails_EN,
    cookiesPolicy: cookiesPolicy_EN,
    cookiesSlideDetails: cookiesSlideDetails_EN,
    disclaimer: disclaimer_EN,
    refundPolicy: refundPolicy_EN,
    refundPolicyProceduresDetails: refundPolicyProceduresDetails_EN,
    privacyPolicy: privacyPolicy_EN,
    privacyPolicySlideDetails: privacyPolicySlideDetails_EN,
    goldenVisa: goldenVisa_EN,
  },
  ar: {
    navbar: navbar_AR,
    footer: footer_AR,
    home: home_AR,
    aboutUs: aboutUs_AR,
    ourPartners: ourPartners_AR,
    ourCompanies: ourCompanies_AR,
    contactUs: contactUs_AR,
    commonContactUs: commonContactUs_AR,
    ourServices: our_services_AR,
    highlightSlideDetails: highlightSlideDetails_AR,
    companyDetails: companyDetails_AR,
    serviceDetails: serviceDetails_AR,
    testimonialDetails: testimonialDetails_AR,
    cookiesPolicy: cookiesPolicy_AR,
    cookiesSlideDetails: cookiesSlideDetails_AR,
    disclaimer: disclaimer_AR,
    refundPolicy: refundPolicy_AR,
    refundPolicyProceduresDetails: refundPolicyProceduresDetails_AR,
    privacyPolicy: privacyPolicy_AR,
    privacyPolicySlideDetails: privacyPolicySlideDetails_AR,
    goldenVisa: goldenVisa_AR,
  },
};

// To show user selected language when page refreshes
function defaultLanguage(language) {
  if (localStorage.getItem("selectedLanguage") === null) {
    localStorage.setItem("selectedLanguage", language);
    return localStorage.getItem("selectedLanguage");
  } else {
    return localStorage.getItem("selectedLanguage");
  }
}

// To show RTL layout if 'ar' is available in local storage
if (localStorage.getItem("selectedLanguage") === "ar") {
  document.body.dir = i18n.dir("ar");
}

//i18N Initialization
i18n.use(initReactI18next).init({
  resources,
  lng: defaultLanguage("en"),
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
