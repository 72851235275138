import useDocumentTitle from "utils/useDocumentTitle";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { cookieSlideDetails } from "data/cookieSlideDetails";
import { useWindowDimensions } from "utils/useWindowDimensions";
import { useState } from "react";
import toTitleCase from "utils/toTitleCase";

export const CookiesPolicy = () => {
  // for translation
  const { t } = useTranslation(["cookiesPolicy", "cookiesSlideDetails"]);

  // To change page title
  useDocumentTitle(toTitleCase(t("cookies_policy")));

  // To show and hide navigation arrows based on window width
  const { width } = useWindowDimensions();
  function toggleNavigation() {
    let navigation;
    if (width <= 768) {
      navigation = false;
    } else {
      navigation = true;
    }
    return navigation;
  }

  // To enable auto height when swiper loads
  const [autoHeight, setAutoHeight] = useState(false);
  setTimeout(() => {
    setAutoHeight(true);
  }, 1000);

  return (
    <div className="CookiesPolicy ">
      <div className="CookiesPolicy_header ">
        <div
          className="CookiesPolicy_header_heading container"
          data-aos="fade-up"
        >
          <h2 className="CookiesPolicy_header_heading_text">
            {t("cookies_policy")}
          </h2>
          <div className="CookiesPolicy_header_heading_underline"></div>
        </div>
        <h3
          className="CookiesPolicy_header_description container"
          data-aos="fade-up"
        >
          {t("cookiesPolicy_description")}
        </h3>
      </div>

      <swiper-container
        auto-height={{ autoHeight }}
        class="CookiesPolicy_slider "
        navigation={toggleNavigation()}
        pagination="true"
        pagination-dynamic-bullets="true"
        pagination-clickable="true"
        grab-cursor="true"
        autoplay-delay="5000"
        speed="1000"
        autoplay-disable-on-interaction="false"
      >
        {cookieSlideDetails.map((item, index) => {
          return (
            <swiper-slide class="CookiesPolicy_slider_slide " key={index}>
              <CookieSlide
                heading={t("cookiesSlideDetails:" + item.heading)}
                description={t("cookiesSlideDetails:" + item.description)}
              />
            </swiper-slide>
          );
        })}
      </swiper-container>
    </div>
  );
};

const CookieSlide = ({ heading, description }) => {
  return (
    <div className="CookieSlide">
      <h2 className="CookieSlide_heading">{heading}</h2>
      <div className="CookieSlide_underline"></div>
      <h2 className="CookieSlide_description">{description}</h2>
    </div>
  );
};
