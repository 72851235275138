import { useTranslation } from "react-i18next";
import { imagesPath } from "utils/imagesPath";
import { useWindowDimensions } from "utils/useWindowDimensions";
import { testimonialDetails } from "../../../data/testimonialDetails";
import "./style.scss";

export const Testimonials = () => {
  // for translation
  const { t } = useTranslation(["home", "testimonialDetails"]);

  // To show and hide navigation arrows based on window width
  const { width } = useWindowDimensions();
  function toggleNavigation() {
    let navigation;
    if (width <= 768) {
      navigation = false;
    } else {
      navigation = true;
    }
    return navigation;
  }

  return (
    <div
      className="Testimonials container"
      style={{ backgroundImage: `url(${imagesPath.BACKGROUND_PATTERN})` }}
    >
      <div className="Testimonials_content ">
        <h2 className="Testimonials_content_heading" data-aos="fade-up">
          {t("testimonials_heading")}
        </h2>
        <h3 className="Testimonials_content_text" data-aos="fade-up">
          {t("testimonials_description")}
        </h3>
        <div
          className="Testimonials_content_topSeparator"
          data-aos="fade-right"
        ></div>
        <swiper-container
          class="Testimonials_content_slider "
          navigation={toggleNavigation()}
          slides-per-view="1"
          space-between="15"
          pagination-dynamic-bullets="true"
          // auto-height="true"
          grab-cursor="true"
          autoplay-delay="5000"
          speed="1000"
          autoplay-disable-on-interaction="false"
        >
          {testimonialDetails.map((item, index) => {
            return (
              <swiper-slide
                class="Testimonials_content_slider_slide"
                key={index}
              >
                <Client
                  name={t("testimonialDetails:" + item.name)}
                  message={t("testimonialDetails:" + item.message)}
                  designation={t("testimonialDetails:" + item.designation)}
                />
              </swiper-slide>
            );
          })}
        </swiper-container>
        <div
          className="Testimonials_content_bottomSeparator"
          data-aos="fade-left"
        ></div>
      </div>
    </div>
  );
};

// Common component for client details
const Client = ({ name, designation, message }) => {
  return (
    <div className="Client" data-aos="fade-up">
      <p className="Client_message" data-aos="fade-up">
        " {message} "
      </p>
      <h4 className="Client_name" data-aos="fade-up">
        {name}
      </h4>
      <h4 className="Client_designation" data-aos="fade-up">
        {designation}
      </h4>
    </div>
  );
};
