export const companyDetails_AR = {
  // Aamer Consultancy
  aamer_consultancy: `آمر للاستشارات`,
  aamer_consultancy_description: `تأسست آمر للاستشارات لتوفير مجموعة متنوعة من الخدمات الاستشارية التي تشمل الخدمات اللوجستية وخدمات الموارد البشرية وخدمات العلاقات العامة وخدمات تطوير المشاريع وإدارتها والأنشطة الإدارية. تسعى الشركة لتلبية متطلبات العملاء و تجاوز توقعاتهم والامتثال لأفضل الممارسات والمعايير الدولية واستخدام أحدث التقنيات في تقديمها لخدماتها.`,

  // Aamer Logistics
  aamer_logistics: `آمر اللوجستية`,
  aamer_logistics_description: `آمر اللوجستية شركة رائدة في تقديم الحلول اللوجستية لخدمات الإيصال الفعّال للوثائق بشكل يلبّي متطلبات جميع العملاء من الأفراد والشركات. عملت الشركة على بناء شراكات استراتيجيةٍ مع الوزارات والجهات الحكومية وشبه الحكومية والشركات لإنشاء منصةٍ موحدةٍ تسمح بالإيصال السلس لمختلف أنواع الوثائق ومواكبة رؤى حكومة دولة الإمارات العربية المتحدة وتطلعاتها وضمان سعادة العملاء والإيصال السريع وذو الجودة العالية للوثائق.

نعمل في آمر دون كلل لتحسين هذه العملية وتسريعها، كما نعمل بشكل متواصل لتنفيذ هذه العمليات على نطاق أوسع حتى نكون صلة الوصل المثالية بين مزودي الخدمات وعملائهم في الإمارات العربية المتحدة. تقدّم الشركة مجموعة كبيرة من خدمات الشركات بدءًا من إنشاء الشركات وتسجيلها بالنيابة عن الأعضاء المؤسسين إلى دعم الشركات في عقد شراكات مع الشركات الأجنبية وإيجاد شركاء ووكلاء محليين وكذلك توفير خدمات السكرتارية والخدمات الأخرى`,

  // Aamer Business
  aamer_business: `آمر للأعمال`,
  aamer_business_description: `آمر للأعمال هي شركةٌ أعمال متخصصة في تقديم الخدمات الإدارية لرجال الأعمال. وتضم مرافق الأعمال التي توفر خدمات السكرتارية والإدارة اللازمة 
تعد شركة آمر للأعمال شركةٌ تجاريةٌ كبرى متخصصة في تقديم الخدمات الإدارية إلى رجال الأعمال. تُقدم مرافق الأعمال تجارية الخاصة بها خدمات السكرتارية اللازمة وإدارة مراكز الطباعة. تُعد آمر للأعمال مقدم خدمة معتمد لمجموعة من الخدمات التي تشمل إعداد وثائق المعاملات التجارية والشخصية للأطراف الثالثة والحصول على التراخيص اللازمة من الجهات الرسمية واستكمال بيانات العملاء وممارسة الصفة القانونية وتقديم الوثائق إلى الهيئات والمؤسسات الحكومية وغير الحكومية وإجراء عمليات المتابعة والانجاز من خلال خدمة عملاء سريعة ويسيرة.`,

  // Aamer Delivery
  aamer_delivery: `آمر للتوصيل`,
  aamer_delivery_description: `آمر للتوصيل هي مركزٍ متعدد الخدمات متخصص في استلام وتسليم المستندات الخالصة للشركات والأفراد على حدٍ سواء، وتهدف آمر للتوصيل إلى تقديم أفضل الحلول والطرق لإنجاز وتخليص واستلام وتسليم المستندات الحكومية بالنيابة عن العملاء من أجل توفير الوقت باستخدام إجراءٍ سهلٍ يسمح للعملاء بتقديم وثائقهم حيثما وجدوا. وتركز الشركة على الاستراتيجيات التالية: ضمان راحة العملاء وسعادتهم، والسرعة وتوفير الوقت والجهد، والخصوصية وضمان سرية معلومات العملاء باستخدام نظام آمن.`,

  // Aamer Digital Hub
  aamer_digital_hub: `منصة آمر الرقمية`,
  aamer_digital_hub_description: `تُعد منصة آمر الرقمية شركة بارزة في مجال تقنية المعلومات حيث تطوّر حلول للبرمجيات المتقدمة والمنصات الرقمية مستهدفة بذلك مختلف الأسواق. أنشأت الشركة منصة تطبيق آمر وبوابة خدمات ذكية ومركز اتصال تشغيلي يجمع بين القطاعين الحكومي والخاص من خلال خدماتها النوعية المتميزة للعملاء. تهدف حلولها الآنية ذات القيمة المضافة إلى توفير خاصية فعالة وشاملة من خلال منصة آمنة.`,
};
