export const aboutUs_EN = {
  // [1.] About Us
  aboutUs: `about us`,
  aboutUs_description: `Aamer is an Emirati company based in Abu Dhabi, the United Arab Emirates. We specialize in providing Document Delivery Services (DDS). At Aamer, we work relentlessly to improve and speed up every aspect of the process, continuously expanding our operations with the aim of being the ideal link between service providers and their customers in the UAE. 
Aamer occupies a prominent position today as a leading provider of Document Delivery Services (DDS) and comprehensive logistics solutions that cater to the needs of all individual and corporate clients.
`,

  // [2.] Our Mission
  ourMission: `our Mission`,
  ourMission_description: `Developing integrated systems for document management and clearance, Aamer aims to provide added-value services to customers and the local community in line with the standards and practices adopted through the platform. This is achieved by building effective partnerships with customer happiness consultants, service, and technology providers to raise productivity levels while respecting social distancing measures, given the current circumstances. Aamer's objective is to keep pace with the various technological and logistical advances in line with the vision of the Abu Dhabi government, aiming to create a cognizant digital society with the purpose of reducing physical gatherings while enhancing work output.`,

  // [3.] Our Vision
  ourVision: `our vision`,
  ourVision_description: `Setting up smart logistics services that promote document delivery and processing in Abu Dhabi to be in line with our government's ambitions; Aamer aims to keep pace with its vision for digital transformation and provide quality services with added value to our customers and visitors. Our aim is to simplify the process of document delivery and follow-up, as well as facilitate document clearance, shipments, and other e-services.`,

  // [4.] Our Goal
  ourGoal: `our goal`,
  ourGoal_description: `At Aamer, our ultimate goal in providing our services is to ensure the happiness and well-being of UAE citizens and residents. We are committed to providing innovative and effortless services that respect international standards, meet the aspirations of our customers, exceed their expectations, and guarantee clients' happiness, upholding the “Customer Happiness Charter."`,

  // [4.] Our Values
  ourValues: `our values`,
  ourValues_value1: `Cooperative`,
  ourValues_value2: `Innovation`,
  ourValues_value3: `Creativity`,
  ourValues_value4: `Transparency`,
  ourValues_value5: `Responsibility`,
  ourValues_value6: `Productivity`,
  ourValues_value7: `Sustainability`,
};
