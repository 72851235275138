import { useTranslation } from "react-i18next";
import { Content } from "./content";
import useDocumentTitle from "utils/useDocumentTitle";
import toTitleCase from "utils/toTitleCase";

export const OurCompanies = () => {
  // for translation
  const { t } = useTranslation("ourCompanies");

  // To change page title
  useDocumentTitle(toTitleCase(t("our_companies")));

  return (
    <div className="OurCompanies">
      <Content />
    </div>
  );
};
