export const companyDetails = [
  {
    name: "aamer_consultancy",
    logo: require("assets/icons/common/companies/aamer-consultancy.png"),
    description: "aamer_consultancy_description",
    path: "/our-companies?aamer_consultancy",
  },
  {
    name: "aamer_logistics",
    logo: require("assets/icons/common/companies/aamer-logistics.png"),
    description: "aamer_logistics_description",
    path: "/our-companies?aamer_logistics",
  },
  {
    name: "aamer_business",
    logo: require("assets/icons/common/companies/aamer-business.png"),
    description: "aamer_business_description",
    path: "/our-companies?aamer_business",
  },
  {
    name: "aamer_delivery",
    logo: require("assets/icons/common/companies/aamer-delivery.png"),
    description: "aamer_delivery_description",
    path: "/our-companies?aamer_delivery",
  },
  {
    name: "aamer_digital_hub",
    logo: require("assets/icons/common/companies/aamer-digital-hub.png"),
    description: "aamer_digital_hub_description",
    path: "/our-companies?aamer_delivery",
  },
];
