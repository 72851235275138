import useDocumentTitle from "utils/useDocumentTitle";
import "./style.scss";
import { useTranslation } from "react-i18next";
import toTitleCase from "utils/toTitleCase";

export const Disclaimer = () => {
  // for translation
  const { t } = useTranslation("disclaimer");

  // To change page title
  useDocumentTitle(toTitleCase(t("disclaimer")));

  return (
    <div className="Disclaimer ">
      <div className="Disclaimer_header ">
        <div className="Disclaimer_header_heading container" data-aos="fade-up">
          <h2 className="Disclaimer_header_heading_text">{t("disclaimer")}</h2>
          <div className="Disclaimer_header_heading_underline"></div>
        </div>
        <h3
          className="Disclaimer_header_description container"
          data-aos="fade-up"
        >
          {t("disclaimer_description")}
        </h3>
      </div>

      <div className="Disclaimer_middle container ">
        <div className="Disclaimer_middle_leftSection" data-aos="fade-right">
          <img
            src={require("assets/icons/common/companies/aamer-digital-hub.png")}
            alt="aamer digital hub"
            className="Disclaimer_middle_leftSection_image"
          />
          <div className="Disclaimer_middle_leftSection_separator"></div>
          <div className="Disclaimer_middle_leftSection_content">
            {t("disclaimer_middleLeftDescription")}
          </div>
          <div className="Disclaimer_middle_leftSection_separator"></div>
        </div>

        <div className="Disclaimer_middle_separator" data-aos="fade-up"></div>

        <div className="Disclaimer_middle_rightSection" data-aos="fade-left">
          {t("disclaimer_middleRightDescription")}
        </div>
      </div>

      <div className="Disclaimer_bottom" data-aos="fade-up">
        <h3 className="Disclaimer_bottom_description container">
          {t("disclaimer_bottomDescription")}
        </h3>
      </div>
    </div>
  );
};
