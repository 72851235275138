import { useTranslation } from "react-i18next";
import { iconsPath } from "utils/iconsPath";
import { imagesPath } from "utils/imagesPath";
import "./style.scss";

export const Content = () => {
  // for translation
  const { t } = useTranslation("aboutUs");

  return (
    <div
      className="Content container"
      style={{ backgroundImage: `url(${imagesPath.BACKGROUND_PATTERN})` }}
    >
      {/* Row 1 */}
      <Row
        leftComponent={
          <TextContent heading={t("aboutUs")}>
            {t("aboutUs_description")}
          </TextContent>
        }
        rightComponent={<Image src={imagesPath.ABOUT_US} alt="about us" />}
      />

      {/* Row 2 */}
      <Row
        leftComponent={<Image src={imagesPath.OUR_VISION} alt="our vision" />}
        leftBackground={true}
        rightComponent={
          <TextContent heading={t("ourVision")}>
            {t("ourVision_description")}
          </TextContent>
        }
      />

      {/* Row 3 */}
      <Row
        leftComponent={
          <TextContent heading={t("ourMission")}>
            {t("ourMission_description")}
          </TextContent>
        }
        rightComponent={
          <Image
            src={imagesPath.OUR_MISSION}
            alt="our mission"
            className="containsImage"
          />
        }
      />

      {/* Row 4 */}
      <Row
        leftComponent={
          <Image
            src={imagesPath.OUR_GOAL}
            alt="our goal"
            className="containsImage"
          />
        }
        rightComponent={
          <TextContent heading={t("ourGoal")}>
            {t("ourGoal_description")}
          </TextContent>
        }
      />

      {/* Row 5 */}
      <Row
        leftComponent={
          <TextContent heading={t("ourValues")}>
            ✔ {t("ourValues_value1")}
            <br />
            <br />✔ {t("ourValues_value2")}
            <br />
            <br />✔ {t("ourValues_value3")}
            <br />
            <br />✔ {t("ourValues_value4")}
            <br />
            <br />✔ {t("ourValues_value5")}
            <br />
            <br />✔ {t("ourValues_value6")}
            <br />
            <br />✔ {t("ourValues_value7")}
          </TextContent>
        }
        rightComponent={<Image src={imagesPath.OUR_VALUES} alt="our values" />}
      />
    </div>
  );
};

// Common row component
const Row = ({
  leftComponent,
  rightComponent,
  leftBackground = false,
  rightBackground = false,
}) => {
  return (
    <div className="Row">
      <div
        className="Row_leftComponent"
        data-aos="zoom-in-right"
        style={leftBackground ? { backgroundColor: "var(--off-white)" } : null}
      >
        {leftComponent}
      </div>
      <div
        className="Row_rightComponent"
        data-aos="zoom-in-left"
        style={rightBackground ? { backgroundColor: "var(--off-white)" } : null}
      >
        {rightComponent}
      </div>
    </div>
  );
};

// Common text component
const TextContent = ({ heading = "heading", children = "description" }) => {
  return (
    <div className="TextContent ">
      <div className="TextContent_heading" data-aos="fade-up">
        <h2 className="TextContent_heading_text">{heading}</h2>
        <div className="TextContent_heading_underline"></div>
      </div>
      <h3 className="TextContent_description" data-aos="fade-up">
        {children}
      </h3>
    </div>
  );
};

// Common image component
const Image = ({ src = iconsPath.LOGO_RED_WITHTEXT, alt = "Aamer Group" }) => {
  return <img className="Image" src={src} alt={alt} />;
};
