import {
  DropDownMenu,
  PhoneWithCountryInput,
  TextAreaInput,
  TextInput,
} from "common/inputs";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import "./style.scss";
import { CommonModal } from "../commonModal";
import { iconsPath } from "utils/iconsPath";
import {
  isEmailValid,
  isInputEmpty,
  isPhoneNumberValid,
} from "utils/validations";
import { contactUsFormSubjects } from "data/contactUsFormSubjects";
import { Link } from "react-router-dom";

export const CommonContactUs = ({ hideLabel = false }) => {
  // for translation
  const { t } = useTranslation("commonContactUs");

  // State to store form inputs data
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    topic: "",
    message: "",
  });

  // State to store form inputs errors
  const [formDataErrors, setFormDataErrors] = useState({
    name: "",
    email: "",
    phone: "",
    topic: "",
    message: "",
  });

  // State for modal visibility
  const [modalVisibility, setModalVisibility] = useState(false);

  // State to track form submission
  const [formSubmissionStatus, setFormSubmissionStatus] = useState("idle");

  // Handle inputs
  function handleInputs(e) {
    let name = e.target.name;
    let value = e.target.value;
    setFormData({ ...formData, [name]: value });
  }

  // Handle dropdown
  function handleDropdown(value) {
    setFormData({ ...formData, topic: value.value });
  }

  // Handle country
  function handlePhone(value) {
    setFormData({ ...formData, phone: value });
  }

  // Handle form submission
  async function handleForm(event) {
    event.preventDefault();

    let tempFormDataErrors = formDataErrors;

    let validateName = isInputEmpty(formData.name);
    let validateEmail = isEmailValid(formData.email);
    let validatePhone = isPhoneNumberValid(formData.phone);
    let validateTopic = isInputEmpty(formData.topic);
    let validateMessage = isInputEmpty(formData.message);

    tempFormDataErrors = {
      name: validateName ? "Please enter your full name" : "",
      email: validateEmail.error,
      phone: validatePhone.error,
      topic: validateTopic ? "Please select a topic" : "",
      message: validateMessage ? "Please type your message" : "",
    };

    setFormDataErrors(tempFormDataErrors);

    if (
      !validateName &&
      validateEmail.success &&
      validatePhone.success &&
      !validateTopic &&
      !validateMessage
    ) {
      try {
        const response = await fetch("https://formspree.io/mdorpzzk", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });

        if (response.ok) {
          // Form submission was successful
          setFormSubmissionStatus("success");
          setModalVisibility(true);
          // Reset the form data
          setFormData({
            name: "",
            email: "",
            phone: "",
            topic: "",
            message: "",
          });
        } else {
          // Form submission failed
          setFormSubmissionStatus("error");
        }
      } catch (error) {
        setFormSubmissionStatus("error");
      }
    } else {
      // Form validation failed
      setFormSubmissionStatus("validationError");
    }
  }

  // Handle modal
  function handleModal() {
    setModalVisibility(!modalVisibility);
  }

  return (
    <div className="CommonContactUs container">
      {hideLabel === false && (
        <div className="CommonContactUs_heading" data-aos="fade-up">
          <h2 className="CommonContactUs_heading_text">
            {t("contactUs_heading")}
          </h2>
          <div className="CommonContactUs_heading_underline"></div>
        </div>
      )}
      <div className="CommonContactUs_content" data-aos="fade-up">
        {/* Form */}
        <form
          className="CommonContactUs_content_form"
          onSubmit={(event) => {
            handleForm(event);
          }}
        >
          <TextInput
            label={t("contactUs_form_name")}
            placeholder={t("contactUs_form_name_placeholder")}
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputs}
            errorMessage={formDataErrors.name}
          />
          <TextInput
            label={t("contactUs_form_email")}
            placeholder={t("contactUs_form_email_placeholder")}
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputs}
            errorMessage={formDataErrors.email}
          />
          <PhoneWithCountryInput
            label={t("contactUs_form_phone")}
            placeholder={t("contactUs_form_phone_placeholder")}
            name="phone"
            value={formData.phone}
            onChange={handlePhone}
            errorMessage={formDataErrors.phone}
          />
          <DropDownMenu
            label={t("contactUs_form_dropDown")}
            placeholder={t("contactUs_form_dropDown_placeholder")}
            ListItems={contactUsFormSubjects.map((item) => t(item))}
            value={t("contactUs_form_dropDown_placeholder")}
            onChange={handleDropdown}
            errorMessage={formDataErrors.topic}
          />
          <TextAreaInput
            label={t("contactUs_form_message")}
            placeholder={t("contactUs_form_message_placeholder")}
            type="text"
            name="message"
            value={formData.message}
            onChange={handleInputs}
            errorMessage={formDataErrors.message}
          />
          <button type="submit" className="CommonContactUs_content_form_button">
            {formSubmissionStatus === "loading"
              ? "Submitting..."
              : t("contactUs_form_buttonText")}
          </button>
        </form>

        <div className="CommonContactUs_content_contactDetails ">
          {/* Address */}
          <div
            className="CommonContactUs_content_contactDetails_group"
            style={{ textTransform: "capitalize" }}
          >
            <h4 className="CommonContactUs_content_contactDetails_group_label">
              {t("contactUs_address")}
            </h4>
            <Link
              to="https://maps.google.com"
              target="_blank"
              className="CommonContactUs_content_contactDetails_group_item"
            >
              {t("contactUs_address_details")}
            </Link>
          </div>
          {/* Phone */}
          <div className="CommonContactUs_content_contactDetails_group">
            <h4 className="CommonContactUs_content_contactDetails_group_label">
              {t("contactUs_phone")}
            </h4>
            <Link
              to="tel:97126519000"
              className="CommonContactUs_content_contactDetails_group_item"
            >
              {t("contactUs_phone_details")}
            </Link>
          </div>
          {/* Email Address */}
          <div className="CommonContactUs_content_contactDetails_group">
            <h4 className="CommonContactUs_content_contactDetails_group_label">
              {t("contactUs_email")}
            </h4>
            <Link
              to="mailto:info@aamergroup.ae"
              className="CommonContactUs_content_contactDetails_group_item"
            >
              info@aamergroup.ae
            </Link>
          </div>
          {/* Website Address */}
          <div className="CommonContactUs_content_contactDetails_group">
            <h4 className="CommonContactUs_content_contactDetails_group_label">
              {t("contactUs_website")}
            </h4>
            <Link
              to="/"
              className="CommonContactUs_content_contactDetails_group_item"
            >
              www.aamergroup.ae
            </Link>
          </div>
        </div>
      </div>

      <CommonModal isOpen={modalVisibility} onClose={handleModal}>
        <div className="CommonContactUs_modal ">
          <img
            className="CommonContactUs_modal_image"
            src={iconsPath.LOGO_RED_WITHOUTTEXT}
            alt="logo"
          />
          <hr className="CommonContactUs_modal_underline" />
          <h3 className="CommonContactUs_modal_heading">
            Thank you for contacting Aamer.
          </h3>
          <h4 className="CommonContactUs_modal_message">
            We appreciate that you’ve taken the time to write us. We’ll get back
            to you very soon.
          </h4>
        </div>
      </CommonModal>
    </div>
  );
};
