export const goldenVisa_EN = {
  golden_visa: `golden visa`,

  golden_visa_section1_heading: `What is Abu Dhabi Golden Visa?`,
  golden_visa_section1_description: `The Abu Dhabi Golden Visa is a residency program offered by the government of Abu Dhabi, United Arab Emirates (UAE). It is designed to attract foreign investors, entrepreneurs, and highly skilled professionals to the emirate by offering them residency status and a range of benefits.

With the Abu Dhabi Golden Visa, eligible individuals and their families can live and work in the UAE for a period of up to ten years, with the possibility of renewal.

The visa also provides benefits such as access to healthcare, education, and investment opportunities. The program is part of the Abu Dhabi government's larger efforts to diversify the economy, enhance competitiveness, and attract international talent to the emirate.

Obtaining the Abu Dhabi Golden Visa can be a complex process, but with the help of a platform like Aamer, it can be made much easier and more accessible for eligible individuals.`,

  golden_visa_section2_heading: `Partnering up with the prestigious Government Entity`,
  golden_visa_section2_description: `We are happy to announce our partnership with Abu Dhabi Residence Office to assist eligible customers with a Golden Visa.
With this partnership, we are now able to offer a personalized service to nominated customers looking to obtain the Abu Dhabi Golden Visa.
We are excited to be able to offer this valuable opportunity to our customers and are confident that our partnership with Abu Dhabi Residence Office will allow us to provide an exceptional level of service.
We believe that this partnership will allow us to make a significant impact on the lives of the UAE citizens and residents.`,

  golden_visa_section3_heading1: `Why choose Aamer?`,
  golden_visa_section3_description1: `Aamer is a digital platform designed to ease the lives of customers applying for various government services. This platform offers a user-friendly and efficient solution for those seeking residency status and benefits in the UAE.
With Aamer, customers can easily navigate the application process, access important information and requirements, and receive expert guidance and support throughout the process. Aamer's goal is to provide a simple and hassle-free experience for obtaining all government services.`,
  golden_visa_section3_heading2: `Aamer’s motto is “Just Login, Leave the Rest To Us”.`,
  golden_visa_section3_description2: `Aamer’s vision is to digitalize the customer journey by simply uploading the documents in the application, and we will take care of the rest. Aamer is the ideal platform to help you achieve your goals and realize your aspirations in the UAE.`,

  golden_visa_section5_heading: `Our Commitment to Our Customers`,
  golden_visa_section5_description: `1. Providing distinguished services in accordance with the standards adopted by the platform.

 2. Providing services and their requirements and completion dates in accordance with the standards adopted by the platform.
 
 3. Providing accurate information for proper services and procedures.
 
 4. Provide simple and quick procedures to serve you easily.
 
 5. Implementation of an integrated call center to answer all your inquiries and follow-up your requests and fulfill them within specific framework.
 
 6. Dealing with your needs professionally and doing our best to meet them with the highest standards and within a specific framework.
 
 7. Providing our services through a knowledgeable team that understands and answers your inquiries electronically.`,

  golden_visa_section6_heading: `GOLDEN VISA FEES`,
  golden_visa_section6_title1: `GOLDEN VISA`,
  golden_visa_section6_content1: `Investor & Real Estate`,
  golden_visa_section6_amount1: ` - 5,300.00 AED`,
  golden_visa_section6_content2: `Other Applicant`,
  golden_visa_section6_amount2: ` - 4,750.00 AED`,

  golden_visa_section6_title2: `GOLDEN VISA - FAMILY MEMBER`,
  golden_visa_section6_content3: `Inside UAE`,
  golden_visa_section6_amount3: ` - 4,750.00 AED`,
  golden_visa_section6_content4: `Outside UAE`,
  golden_visa_section6_amount4: ` - 4,200 AED`,
  golden_visa_section6_content5: `Renew Inside UAE`,
  golden_visa_section6_amount5: ` - 3,200 AED`,
  golden_visa_section6_note: `* The above prices do not include Cancellation & Medical services.
* Note that the above includes all government charges, VAT, bank charges & Aamer changes.
`,
};
