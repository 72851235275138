export const disclaimer_AR = {
  disclaimer: `إخلاء المسؤولية`,
  disclaimer_description: `تُعنى منصة آمر الرقمية هذه بتقديم الخدمات الموضحة على الموقع فقط ولا تتضمن أي عرض أو طلب أو نصيحة أو توصية يقدمها هذا الموقع أو خدماته فيما يتعلق بأي منتج أو خدمة أو خدمة استثمار أو عرض بيع أو ترويج ولا تشجع على شراء أي ممتلكات أو منتجات أو سلع أو أوراق مالية أو ضمان، وتتسم أي معلومات أو إعلانات متاحة على الموقع الإلكتروني فيما يتعلق بأي مما سبق ذكره بأنها معلومات عامة أو مقدمة من طرف ثالث مسؤول بمفرده عن معلوماته أو إعلاناته، إلا أننا نبذل جهودًا مضنية للتحقق من صحة المعلومات و/ أو الإعلانات التي يضعها أي طرف ثالث على الموقع.
  
   بيد أن ذلك لا يعد ضمانًا على دقة تلك المعلومات أو تأكيدًا من المنصة على صحتها، وبالتالي لا تتحمل المنصة أي مسؤولية أيًا كانت عن أي من المعلومات و/ أو الإعلانات الواردة في هذا الموقع، وعليه توصي منصة آمر الرقمية بالحصول على مشورة مهنية مستقلة قبل اتخاذ أي قرار أو استثمار، وتوضح أن اختيارات المستخدم وإجراءاته تظل ضمن نطاق مسؤوليته منفردًا دون أي مسؤولية على منصة آمر الرقمية.`,
  disclaimer_middleLeftDescription: `تؤكد منصة آمر الرقمية أن المواد الموجودة على هذا الموقع هي إعلانات تابعة لأطراف ثالثة ويتحمل واضعها/المعلن عنها مسؤوليتها بالكامل، أو أنها معلومات عامة لا تشكل أي مشورة قانونية أو فنية أو مهنية بأي شكل من الأشكال ولا تتحمل المنصة مسؤولية أي ضرر أو خسارة ناجمة عن الاعتماد على تلك الإعلانات أو المعلومات المتعلقة بأي عرض أو طلب أو نصيحة أو توصية لأي منتج أو خدمة أو خدمة استثمارية أو عرض بيع أو ترويج، كما لا تشجع شراء أي ممتلكات أو منتجات أو سلع أو أوراق مالية أو ضمان لأي نوع ولا تقدم منصة آمر الرقمية ضمانًا صريحًا أو ضمنيًا على صحة هذه المعلومات، ولا يمكنها تقديم ضمان صريح أو ضمني على توفرها في أي منطقة من العالم`,
  disclaimer_middleRightDescription: `يقر المستخدم بأنه على علم تام بأن منصة آمر الرقمية و/ أو أي من الشركات التابعة لها و/ أو أي من موظفيها، لا يتحملون مسؤولية صحة أي من المعلومات أو الإعلانات المشار إليها أو اكتمالها، وأن أي من الإعلانات أو المعلومات الموجودة على الموقع قد يتم تمديدها و/ أو تغييرها أو حذفها جزئيًا أو كليًا من قبل واضعها/ المعلن عنها دون إشعار منفصل ودون أن تكون منصة آمر الرقمية مسؤولة عن ذلك، كما يقر المستخدم لخدمات المنصة المدرجة حصريًا على الموقع أن استخدامه يشكل إقرارًا صريحًا بأن منصة آمر الرقمية لا تتحمل أي مسؤولية عن أي معلومات أو إعلانات متاحة على الموقع و أنها لن تقوم بمقاضاة منصة آمر الرقمية بأي شكل من الأشكال فيما يتعلق بأي من الإعلانات أو المعلومات المشار إليها.
  
   تُعنى منصة آمر الرقمية فقط بتحديث الإعلانات أو المعلومات المتاحة على الموقع وفقاً لمتطلبات مصدرها، سواء كان واضعها/المعلن عنها، وفيما يخص تكامل المعلومات أو الروابط الإعلانية المقدمة من واضعها/المعلن عنها على الصفحة، تتسم تلك بأنها خدمة إضافية لمستخدمي الموقع أو قرائه ولا تشارك منصة آمر الرقمية ولا أي من موظفيها في إنشاء تلك الروابط أو محتواها، ولا تتحمل مسؤولية محتوى أي رابط من هذا القبيل، ويتحمل المستخدم وحده مسؤولية استخدام أي من هذه المعلومات أو الإعلانات، كما يتحمل المستخدم مسؤولية طرح الأسئلة ووضع التعليقات أو الحصول على معلومات من واضع الرابط أو المعلن عنه. كما لا تتحمل منصة آمر الرقمية أو أي من الشركات التابعة لها و/ أو أي من موظفيها، مسؤولية أي معلومات أو إعلانات أو أي خسارة يتعرض لها أي شخص لأي سبب أو إجراء أو عدم اعتماد على محتوى المواقع التي تؤدي إليها أي من المعلومات أو الروابط الإعلانية.`,
  disclaimer_bottomDescription: `لا تُعد المعلومات المقدمة على منصة آمر الرقمية مخصصة للنشر أوالاستخدام بواسـطة أي شخص أو كيان في أي ولاية قضائية أو بلد حيث يكون هذا التوزيع أو الاستخدام مخالفًا للقانون أو اللوائح أو يعرض منصة آمر الرقمية و/أو أي من الشركات التابعة لها و / أو أي من موظفيها لأي مسؤولية قانونية من أي نوع ضمن نطاق تلك الولاية القضائية أو الدولة.`,
};
