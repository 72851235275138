import { useTranslation } from "react-i18next";
import { serviceDetails } from "data/serviceDetails";
import { useState } from "react";
import { useWindowDimensions } from "utils/useWindowDimensions";
import { Link } from "react-router-dom";
import { PrimaryButton } from "common/buttons";
import "./style.scss";
import useDocumentTitle from "utils/useDocumentTitle";
import toTitleCase from "utils/toTitleCase";

export const OurServices = () => {
  // for translation
  const { t } = useTranslation(["ourServices", "serviceDetails"]);

  // To change page title
  useDocumentTitle(toTitleCase(t("our_services")));

  // To show and hide navigation arrows based on window width
  const { width } = useWindowDimensions();
  function toggleNavigation() {
    let navigation;
    if (width <= 1250) {
      navigation = false;
    } else {
      navigation = true;
    }
    return navigation;
  }

  // To enable auto height when swiper loads
  const [autoHeight, setAutoHeight] = useState(false);
  setTimeout(() => {
    setAutoHeight(true);
  }, 1000);

  return (
    <div className="OurServices">
      <div className="OurServices_header">
        <div className="OurServices_header_heading" data-aos="fade-up">
          <h2 className="OurServices_header_heading_text">
            {t("our_services")}
          </h2>
          <div className="OurServices_header_heading_underline"></div>
        </div>
      </div>

      <div className="OurServices_servicesContainer container">
        {serviceDetails.map((item, index) => {
          return (
            <Service
              key={index}
              title={t("serviceDetails:" + item.title)}
              icon={item.icon}
              path={item.title}
            />
          );
        })}
      </div>

      <div className="OurServices_services_slider container ">
        <swiper-container
          auto-height={{ autoHeight }}
          navigation={toggleNavigation()}
          pagination="true"
          pagination-clickable="true"
          pagination-dynamic-bullets="true"
          space-between="25"
          grab-cursor="true"
          autoplay-delay="5000"
          speed="1000"
          autoplay-disable-on-interaction="false"
        >
          {serviceDetails.map((item, index) => {
            return (
              <swiper-slide key={index}>
                <ServiceDetails
                  icon={item.icon}
                  heading={t("serviceDetails:" + item.title)}
                  description={
                    t("serviceDetails:" + item.shortDescription) +
                    "\n\n" +
                    t("serviceDetails:" + item.longDescription)
                  }
                  buttonText={t("learn_more")}
                  path={item.title}
                ></ServiceDetails>
              </swiper-slide>
            );
          })}
        </swiper-container>
      </div>
    </div>
  );
};

// Common component for service
const Service = ({ title, icon, path }) => {
  return (
    <Link
      to={`/our-services/${path}`}
      className="Service"
      data-aos="zoom-in-up"
    >
      <div className="Service_icon">
        <img src={icon} alt={title} />
      </div>
      <h4 className="Service_title">{title}</h4>
    </Link>
  );
};

// Common component for service details
const ServiceDetails = ({ icon, heading, description, buttonText, path }) => {
  return (
    <div className="ServiceDetails ">
      <div className="ServiceDetails_left ">
        <img
          src={icon}
          alt="service icon"
          className="ServiceDetails_left_icon"
        />
        <h3 className="ServiceDetails_left_heading">{heading}</h3>
        <div className="ServiceDetails_left_underline"></div>
      </div>
      <div className="ServiceDetails_right" data_aos="fade-up">
        <p className="ServiceDetails_right_description">{description}</p>
        <PrimaryButton
          title={buttonText}
          path={path}
          className={"ServiceDetails_button"}
          data_aos="fade-up"
        />
      </div>
    </div>
  );
};
