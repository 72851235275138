// Empty Input Validation
export function isInputEmpty(string) {
  if (string === "") {
    return true;
  } else {
    return false;
  }
}

// Phone Number Validation
export function isPhoneNumberValid(phoneNumber) {
  const phoneNumberRegex = /^[+\-\d]+$/;
  if (phoneNumber === "") {
    return {
      error: "Please enter your phone number",
      success: false,
    };
  } else if (phoneNumber.length <= 4) {
    return {
      error: "Please enter your phone number",
      success: false,
    };
  } else if (!phoneNumberRegex.test(phoneNumber)) {
    return {
      error: "Please enter a valid phone number",
      success: false,
    };
  } else {
    return {
      error: "",
      success: true,
    };
  }
}

// Email Validation
export function isEmailValid(email) {
  const emailRegex =
    /^[-!#$%&'*+0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

  if (email === "") {
    return {
      error: "Please enter your email address",
      success: false,
    };
  } else if (!emailRegex.test(email)) {
    return {
      error: "Please enter a valid email address",
      success: false,
    };
  } else {
    return {
      error: "",
      success: true,
    };
  }
}
