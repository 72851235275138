export const commonContactUs_AR = {
  // [6.] Contact Us
  contactUs_heading: `اتصل بنا`,

  // Form
  contactUs_form_name: `اسم`,
  contactUs_form_name_placeholder: `أدخل اسمك الكامل`,
  contactUs_form_email: `بريد إلكتروني`,
  contactUs_form_email_placeholder: `أدخل عنوان بريدك الالكتروني`,
  contactUs_form_phone: "الهاتف",
  contactUs_form_phone_placeholder: `أدخل رقم هاتفك`,
  contactUs_form_dropDown: `موضوع`,
  contactUs_form_dropDown_placeholder: `يختار`,
  contactUs_form_dropDown_option_goldenVisa: `الفيزا الذهبية`,
  contactUs_form_dropDown_option_translationAndAttestation: `الترجمة والتصديق`,
  contactUs_form_dropDown_option_immigrationAndId: `الهجرة والهوية`,
  contactUs_form_dropDown_option_logistics: `السوقيات`,
  contactUs_form_dropDown_option_technical: `تقني`,
  contactUs_form_dropDown_option_csp: `سي اس بي`,
  contactUs_form_dropDown_option_feedbackAndComplaint: `ردود الفعل والشكوى`,
  contactUs_form_message: `رسالة`,
  contactUs_form_message_placeholder: `اشرح استفسارك هنا ...`,

  contactUs_form_buttonText: `إرسال رسالة`,

  // Contact Details
  contactUs_address: `عنوان`,
  contactUs_address_details: `مجموعة آمر، ص. ب. 2913 ، أبوظبي، الإمارات العربية المتحدة`,
  contactUs_phone: `الهاتف`,
  contactUs_phone_details: `97126519000+`,
  contactUs_email: `البريد الإلكتروني`,
  contactUs_website: `الموقع الإلكتروني`,
};
