export const ourPartners_EN = {
  ourPartners_heading: `our partners`,
  ourPartners_emiratesPost: `Emirates Post & Universal Postal Union:`,

  ourPartners_description: `"Emirates Post" is the leading postal and express delivery provider in the region, offering flexible and cost-effective international and domestic post and courier delivery solutions. It is the largest domestic service provider, with 80+ Customer Happiness Centres and an extensive daily line-haul network across all seven emirates. Through integrated and automated back-end solutions, Emirates Post fully supports e-commerce businesses with seamless last-mile deliveries.

The Emirates Post Group is also the official entity responsible for licensing all postal, courier, and logistics services within the UAE, following the procedure at AAMER Platform, as Emirates Post is a public joint-stock company operating across the UAE.

In cooperation with the Emirates Group, Aamer Group provides courier solutions and logistics services in the UAE. Additionally, Emirates Post is a member of the Universal Postal Union (UPU), which is an international organization that coordinates postal policies among member countries and ensures the smooth flow of mail and parcels across borders.
`,
};
