import AOS from "aos";
import "aos/dist/aos.css";
import { Footer } from "common/footer";
import { Loader } from "common/loader";
import { Navbar } from "common/navbar";
import { AboutUs } from "pages/aboutUs";
import { OurCompanies } from "pages/ourCompanies";
import { OurServices } from "pages/ourServices";
import { OurPartners } from "pages/ourPartners";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { register } from "swiper/element/bundle";
import ScrollToTop from "utils/ScrollToTop";
import { Home } from "./pages/home";
import { ContactUs } from "pages/contactUs";
import { PrivacyPolicy } from "pages/privacyPolicy";
import { Disclaimer } from "pages/disclaimer";
import { RefundPolicy } from "pages/refundPolicy";
import { CookiesPolicy } from "pages/cookiePolicy";
import { ServiceDetails } from "pages/serviceDetails";
import { GoldenVisa } from "pages/goldenVisa";

function App() {
  // Initializing AOS (Animate On Scroll) library.
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  // Initializing Swiper library.
  register();

  // To show loader till page loads
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 750);
  }, []);

  return (
    <React.StrictMode>
      <BrowserRouter>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path="/" element={loading ? <Loader /> : <Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/our-companies" element={<OurCompanies />} />
          <Route path="/our-partners" element={<OurPartners />} />
          <Route path="/our-services" element={<OurServices />} />
          <Route path="/our-services/:serviceId" element={<ServiceDetails />} />
          <Route path="/our-services/golden_visa" element={<GoldenVisa />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookies-policy" element={<CookiesPolicy />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </React.StrictMode>
  );
}

export default App;
