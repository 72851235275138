import { useTranslation } from "react-i18next";
import "./style.scss";
import { serviceDetails } from "data/serviceDetails";
import useDocumentTitle from "utils/useDocumentTitle";
import toTitleCase from "utils/toTitleCase";
import { iconsPath } from "utils/iconsPath";
import { imagesPath } from "utils/imagesPath";

export const GoldenVisa = () => {
  // for translation
  const { t } = useTranslation(["goldenVisa", "serviceDetails"]);

  var filteredService = serviceDetails.filter(
    (item) => item.title === "golden_visa"
  )[0];

  //   // To change page title
  useDocumentTitle(toTitleCase(t(filteredService.title)));

  return (
    <div className="GoldenVisa">
      <div className="GoldenVisa_header">
        <div className="GoldenVisa_header_heading" data-aos="fade-up">
          <h2 className="GoldenVisa_header_heading_text">{t("golden_visa")}</h2>
          <div className="GoldenVisa_header_heading_underline"></div>
        </div>
      </div>

      <div className="GoldenVisa_section1 container" data-aos="fade-up">
        <div className="GoldenVisa_section1_left" data-aos="fade-up">
          <img
            src={filteredService.icon}
            alt="service icon"
            className="GoldenVisa_section1_left_icon"
          />
          <h3 className="GoldenVisa_section1_left_heading">
            {t(filteredService.title)}
          </h3>
          <div className="GoldenVisa_section1_left_underline"></div>
        </div>
        <div className="GoldenVisa_section1_right" data-aos="fade-up">
          <p className="GoldenVisa_section1_right_heading">
            {t("golden_visa_section1_heading")}
          </p>
          <p className="GoldenVisa_section1_right_description">
            {t("golden_visa_section1_description")}
          </p>
        </div>
      </div>

      {/* <div className="GoldenVisa_section2 container" data-aos="fade-up">
        <img
          className="GoldenVisa_section2_image"
          src={iconsPath.GOLDEN_VISA_SECTION2}
          alt="abu dhabi resident office"
        />
        <p className="GoldenVisa_section2_heading">
          {t("golden_visa_section2_heading")}
        </p>
        <p className="GoldenVisa_section2_description">
          {t("golden_visa_section2_description")}
        </p>
      </div> */}

      <div className="GoldenVisa_section3 container" data-aos="fade-up">
        <img
          src={iconsPath.LOGO_RED_WITHTEXT}
          alt="aamer group"
          className="GoldenVisa_section3_left"
        />
        <div className="GoldenVisa_section3_right" data-aos="fade-up">
          <p className="GoldenVisa_section3_right_heading">
            {t("golden_visa_section3_heading1")}
          </p>
          <p className="GoldenVisa_section3_right_description">
            {t("golden_visa_section3_description1")}
          </p>
          <p className="GoldenVisa_section3_right_heading">
            {t("golden_visa_section3_heading2")}
          </p>
          <p className="GoldenVisa_section3_right_description">
            {t("golden_visa_section3_description2")}
          </p>
        </div>
      </div>

      <img
        className="GoldenVisa_section4"
        data-aos="fade-up"
        src={imagesPath.GOLDEN_VISA_SECTION4}
        alt="golden visa process"
      />

      <div className="GoldenVisa_section5 ">
        <div
          className="GoldenVisa_section5_heading container"
          data-aos="fade-up"
        >
          <h2 className="GoldenVisa_section5_heading_text">
            {t("golden_visa_section5_heading")}
          </h2>
          <div className="GoldenVisa_section5_heading_underline"></div>
        </div>
        <h3
          className="GoldenVisa_section5_description container"
          data-aos="fade-up"
        >
          {t("golden_visa_section5_description")}
        </h3>
      </div>

      <div className="GoldenVisa_section6 ">
        <div
          className="GoldenVisa_section6_heading container"
          data-aos="fade-up"
        >
          <h2 className="GoldenVisa_section6_heading_text">
            {t("golden_visa_section6_heading")}
          </h2>
          <div className="GoldenVisa_section6_heading_underline"></div>
        </div>
        <h3 className="GoldenVisa_section6_title" data-aos="fade-up">
          {t("golden_visa_section6_title1")}
        </h3>
        <h4 className="GoldenVisa_section6_content" data-aos="fade-up">
          {t("golden_visa_section6_content1")}
          <span>{t("golden_visa_section6_amount1")}</span>
        </h4>
        <h4 className="GoldenVisa_section6_content" data-aos="fade-up">
          {t("golden_visa_section6_content2")}
          <span>{t("golden_visa_section6_amount2")}</span>
        </h4>
        <div className="GoldenVisa_section6_underline" data-aos="fade-up"></div>
        <h3 className="GoldenVisa_section6_title" data-aos="fade-up">
          {t("golden_visa_section6_title2")}
        </h3>
        <h4 className="GoldenVisa_section6_content" data-aos="fade-up">
          {t("golden_visa_section6_content3")}
          <span>{t("golden_visa_section6_amount3")}</span>
        </h4>
        <h4 className="GoldenVisa_section6_content" data-aos="fade-up">
          {t("golden_visa_section6_content4")}
          <span>{t("golden_visa_section6_amount4")}</span>
        </h4>
        <h4 className="GoldenVisa_section6_content" data-aos="fade-up">
          {t("golden_visa_section6_content5")}
          <span>{t("golden_visa_section6_amount5")}</span>
        </h4>
        <p className="GoldenVisa_section6_note" data-aos="fade-up">
          {t("golden_visa_section6_note")}
        </p>
      </div>
    </div>
  );
};
