import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { iconsPath } from "utils/iconsPath";
import { imagesPath } from "utils/imagesPath";
import { companySlideDetails } from "../../../data/companySlideDetails";
import "./style.scss";

export const Content = () => {
  // for translation
  const { t } = useTranslation("ourCompanies");

  useEffect(() => {
    const targetId = window.location.href.split("?")[1];

    if (targetId) {
      const targetElement = document.getElementById(targetId);

      if (targetElement) {
        const offset = 100;
        const topPosition =
          targetElement.getBoundingClientRect().top + window.scrollY - offset;

        window.scrollTo({ top: topPosition, behavior: "smooth" });
      }
    }
  }, []);

  return (
    <div
      className="Content container"
      style={{ backgroundImage: `url(${imagesPath.BACKGROUND_PATTERN})` }}
    >
      <div className="Content_heading" data-aos="fade-up">
        <h2 className="Content_heading_text">{t("our_companies")}</h2>
        <div className="Content_heading_underline"></div>
      </div>
      <div className="Content_rows">
        {companySlideDetails.map((item, index) => {
          return (
            <Row
              id={item.id}
              key={index}
              firstComponent={
                <Image
                  src={item.sideImage}
                  alt={item.name}
                  secondComponent={<Company />}
                />
              }
              secondComponent={
                <Company logo={item.logo} description={t(item.description)} />
              }
            />
          );
        })}
      </div>
    </div>
  );
};

// Common row component
const Row = ({ firstComponent, secondComponent, id }) => {
  return (
    <div className="Row" id={id}>
      <div className="Row_firstComponent" data-aos="zoom-in-right">
        {firstComponent}
      </div>
      <div className="Row_secondComponent" data-aos="zoom-in-left">
        {secondComponent}
      </div>
    </div>
  );
};

// Common component for company details
const Company = ({ logo, description }) => {
  return (
    <div className="Company" data-aos="fade-right">
      <img className="Company_image" src={logo} alt="" data-aos="fade-up" />
      <p className="Company_paragraph" data-aos="fade-up">
        {description}
      </p>
    </div>
  );
};

// Common image component
const Image = ({ src = iconsPath.LOGO_RED_WITHTEXT, alt = "Aamer Group" }) => {
  return <img className="Image " src={src} alt={alt} />;
};
