import { PrimaryButton } from "common/buttons";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { iconsPath } from "utils/iconsPath";
import { imagesPath } from "utils/imagesPath";
import "./style.scss";

export const Hero = () => {
  // for translation
  const { t } = useTranslation("home");

  return (
    <div className="Hero">
      {/* Hero > Landing */}
      <div
        className="Hero_landing"
        style={{ backgroundImage: `url(${imagesPath.BACKGROUND_PATTERN})` }}
      >
        <img
          className="Hero_landing_image"
          data-aos="zoom-in"
          src={iconsPath.LOGO_RED_WITHTEXT}
          alt="Aamer Group"
        />
        <div className="Hero_landing_downloadButton">
          <Link
            to="https://apps.apple.com/ae/app/aamer-app/id1618423347"
            target="_blank"
            rel="noopener noreferrer"
            data-aos="zoom-in"
            data-aos-delay="300"
          >
            <img src={iconsPath.APP_STORE} alt="app store" />
          </Link>
          <Link
            to="https://play.google.com/store/apps/details?id=com.aamer.ae"
            target="_blank"
            rel="noopener noreferrer"
            data-aos="zoom-in"
            data-aos-delay="300"
          >
            <img src={iconsPath.PLAY_STORE} alt="play store" />
          </Link>
        </div>
      </div>

      {/* Hero > Line */}
      <div className="Hero_line animate__animated animate__fadeInUp animate__delay-1s"></div>

      {/* Hero > About Us */}
      <div
        className="Hero_aboutUs"
        style={{ backgroundImage: `url(${imagesPath.HOME_ABOUT_US})` }}
      >
        <div className="Hero_aboutUs_content container">
          <div className="Hero_aboutUs_content_heading" data-aos="fade-up">
            <h1 className="Hero_aboutUs_content_heading_text">
              {t("hero_aboutUs_heading")}
            </h1>
          </div>
          <p className="Hero_aboutUs_content_paragraph" data-aos="fade-up">
            {t("hero_aboutUs_description")}
          </p>
          <PrimaryButton
            title={t("hero_aboutUs_buttonText")}
            path="/about-us"
            data_aos="fade-up"
          />
        </div>
        <div className="Hero_aboutUs_overlay"></div>
      </div>
    </div>
  );
};
