export const aboutUs_AR = {
  // [1.] About Us
  aboutUs: `نبذة عنا`,
  aboutUs_description: `آمر هي شركة إماراتية، مقرها أبوظبي، الإمارات العربية المتحدة، ونحن متخصصون بتوفير خدمات تسليم المستندات. نحن في شركة "آمر" نعمل بلا كلل لتحسين وتسريع كل جانب من جوانب العملية، موسِّعين عملياتنا باستمرار بهدف أن نكون صلة الوصل المثالية بين مزودي الخدمات وعملائهم في دولة الإمارات العربية المتحدة.

تحتل آمر مكانة بارزة في الوقت الحالي كونها مزودًا رائدًا لخدمات نقل الوثائق والمستندات ومقدمًا للحلول اللوجستية الشاملة التي تلبي احتياجات كافة العملاء الأفراد منهم والشركات`,

  // [2.] Our Mission
  ourMission: `رسالتنا`,
  ourMission_description: `تطوير أنظمة متكاملة لإدارة الوثائق وتخليصها وتقديم خدمات ذات قيمة  مضافة للمتعاملين والمجتمع المحلي بما يتوافق مع معايير المنصة وممارساتها المعتمدة، وذلك عن طريق بناء شراكات فعالة مع مستشاري سعادة المتعاملين ومزودي الخدمة والتكنولوجيا سعياً لرفع مستويات الإنتاجية مع مراعاة تدابير التباعد الاجتماعي، في ظل الظروف الحالية، وتهدف آمر إلى مواكبة التطورات التكنولوجية واللوجستية المختلفة التي تتوافق مع رؤية حكومة ابوظبي الرشيدة، وخلق مجتمع رقمي واعِ بهدف تقليل التجمعات مع تحسين مخرجات العمل.`,

  // [3.] Our Vision
  ourVision: `رؤيتنا`,
  ourVision_description: `تقديم خدمات لوجستية ذكية تحسّن من تجربة تسليم المستندات ومعاملاتها في أبوظبي من أجل أن تتوافق مع طموحات حكومتنا؛ وتسعى شركة آمر إلى مواكبة رؤيتها للتحول الرقمي وتقديم خدمات عالية الجودة ذات قيمة مضافة لعملائنا وزوارنا، ونهدف إلى تبسيط عملية تسليم المستندات ومتابعتها ، و تسهيل تخليص المستندات والشحنات والخدمات الإلكترونية الأخرى`,

  // [4.] Our Goal
  ourGoal: `هدفنا`,
  ourGoal_description: `يتمثل هدف آمر الأساسي من تقديم الخدمات في ضمان سعادة ورفاهية مواطني دولة الإمارات العربية المتحدة والمقيمين فيها، حيث إننا ملتزمون بتقديم خدمات مبتكرة وسهلة تتوافق مع المعايير عالمية، وتلبي تطلعات عملائنا بل تتجاوزها وتضمن تحقيق سعادة العملاء وفقًا "لميثاق إسعاد المتعاملين".`,

  // [4.] Our Values
  ourValues: `قيمنا`,
  ourValues_value1: `التعاون`,
  ourValues_value2: `الابتكار`,
  ourValues_value3: `الإبداع`,
  ourValues_value4: `الشفافية`,
  ourValues_value5: `المسؤولية`,
  ourValues_value6: `الإنتاجية`,
  ourValues_value7: `الاستدامة`,
};
