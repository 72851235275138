export const highlightSlideDetails_EN = {
  // Slide 1
  highlights_slide1_title: `data entry`,
  highlights_slide1_description: `Id aliquet risus feugiat in ante metus dictum at. Dui nunc mattis enim ut tellus elementum sagittis. Convallis posuere morbi leo urna molestie at elementum eu. Scelerisque felis imperdiet proin fermentum leo vel orci porta non.`,

  // Slide 2
  highlights_slide2_title: `process`,
  highlights_slide2_description: `Id aliquet risus feugiat in ante metus dictum at. Dui nunc mattis enim ut tellus elementum sagittis. Convallis posuere morbi leo urna molestie at elementum eu. Scelerisque felis imperdiet proin fermentum leo vel orci porta non.`,

  // Slide 3
  highlights_slide3_title: `tracking`,
  highlights_slide3_description: `Id aliquet risus feugiat in ante metus dictum at. Dui nunc mattis enim ut tellus elementum sagittis. Convallis posuere morbi leo urna molestie at elementum eu. Scelerisque felis imperdiet proin fermentum leo vel orci porta non.`,

  // Slide 4
  highlights_slide4_title: `transfer`,
  highlights_slide4_description: `Id aliquet risus feugiat in ante metus dictum at. Dui nunc mattis enim ut tellus elementum sagittis. Convallis posuere morbi leo urna molestie at elementum eu. Scelerisque felis imperdiet proin fermentum leo vel orci porta non.`,
};
