import { useTranslation } from "react-i18next";
import "./style.scss";
import { serviceDetails } from "data/serviceDetails";
import useDocumentTitle from "utils/useDocumentTitle";
import { useParams } from "react-router-dom";
import toTitleCase from "utils/toTitleCase";

export const ServiceDetails = () => {
  // Accessing the dynamic serviceID from the URL
  let { serviceId } = useParams();

  // for translation
  const { t } = useTranslation("serviceDetails");

  var filteredService = serviceDetails.filter(
    (item) => item.title === serviceId
  )[0];

  //   // To change page title
  useDocumentTitle(toTitleCase(t(filteredService.title)));

  return (
    <div className="ServiceDetails">
      <div className="ServiceDetails_header">
        <div className="ServiceDetails_header_heading" data-aos="fade-up">
          <h2 className="ServiceDetails_header_heading_text">
            {t(filteredService.title)}
          </h2>
          <div className="ServiceDetails_header_heading_underline"></div>
        </div>
      </div>

      <div className="ServiceDetails_content container" data-aos="fade-up">
        {t(filteredService.shortDescription) !== "" && (
          <p className="ServiceDetails_content_top " data-aos="fade-up">
            {t(filteredService.shortDescription)}
          </p>
        )}
        <div className="ServiceDetails_content_bottom" data-aos="fade-up">
          <div
            className="ServiceDetails_content_bottom_left"
            data-aos="fade-up"
          >
            <img
              src={filteredService.icon}
              alt="service icon"
              className="ServiceDetails_content_bottom_left_icon"
            />
            <h3 className="ServiceDetails_content_bottom_left_heading">
              {t(filteredService.title)}
            </h3>
            <div className="ServiceDetails_content_bottom_left_underline"></div>
          </div>
          <div
            className="ServiceDetails_content_bottom_right"
            data-aos="fade-up"
          >
            <p className="ServiceDetails_content_bottom_right_description">
              {t(filteredService.longDescription)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
