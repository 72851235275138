import { Link } from "react-router-dom";
import "./style.scss";

export const PrimaryButton = ({
  title = "button title",
  path = "/",
  style,
  className,
  onClick,
  data_aos,
  type,
}) => {
  return (
    <Link
      to={path}
      style={style}
      className={`primaryButton ${className}`}
      onClick={onClick}
      data-aos={data_aos}
      type={type}
    >
      {title}
    </Link>
  );
};
