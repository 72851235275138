export const companyDetails_EN = {
  // Aamer Consultancy
  aamer_consultancy: `aamer consultancy`,
  aamer_consultancy_description: `Aamer Consultancy was established to provide a wide range of consultancy services that encompass logistics, human resources, public relations, project development, project management, and administrative activities. The company endeavors to meet the requirements of customers, exceed their expectations, follow the best international practices and standards, and adopt cutting-edge technology in the delivery of its services.
`,

  // Aamer Logistics
  aamer_logistics: `aamer logistics`,
  aamer_logistics_description: `Aamer Logistics is a leading provider of logistics solutions for efficient document delivery services that cater to the needs of all individual and corporate clients. The company has built strategic partnerships with ministries, governmental and semi-governmental entities, and companies to create a unified platform that ensures the smooth delivery of all types of documents while keeping pace with the visions and aspirations of the government of the United Arab Emirates. This guarantees customer happiness and ensures fast and high-quality document delivery services.

At Aamer, we work relentlessly to improve and speed up every aspect of the process, continuously expanding our operations with the aim of being the ideal link between service providers and their customers in the UAE. The company provides a wide range of corporate services, from setting up and registering businesses on behalf of founding members to assisting companies in building partnerships with foreign companies, finding local partners and agents, as well as providing secretarial and other related services.
`,

  // Aamer Business
  aamer_business: `aamer business`,
  aamer_business_description: `Aamer Business is the ultimate business company that specializes in administrative services for businessmen. It includes business facilities that provide the necessary secretarial services and management.
  
  Aamer Business is a major business firm that specializes in providing administrative services to businessmen. Its business facilities offer the necessary secretarial services and management of printing centers. Aamer Business is an accredited service provider offering a range of services that include the preparation of commercial and personal transaction documents on behalf of third parties, the procurement of necessary licenses from official authorities, the completion of customers’ data and exercise of legal capacity, the submission of documents to governmental and non-governmental bodies and institutions, and the follow-up and finalization of processes through fast and effortless customer service.
  `,

  // Aamer Delivery
  aamer_delivery: `aamer delivery`,
  aamer_delivery_description: `Aamer Delivery is a one-stop center specializing in receiving and delivering finalized documents for companies and individuals alike. Aamer Delivery aims to provide the best solutions and approaches for the completion, clearance, collection, and delivery of all government documents on behalf of customers while saving time with an effortless process, allowing customers to submit their documents from wherever they are. The company focuses on the following strategies: guaranteeing customers' comfort and happiness, speed: saving time and effort, and privacy: ensuring the confidentiality of customers' information by using a secure system.`,

  // Aamer Digital Hub
  aamer_digital_hub: `aamer digital hub`,
  aamer_digital_hub_description: `Aamer Digital Hub Platform is a distinguished IT company that develops advanced software solutions and Aamer Digital Hub platforms targeting various markets. The company created the Aamer Application platform, a smart services portal, and an operating call center that combines the private and government sectors, offering outstanding quality services for customers. Its real-time value-added solutions are designed to provide efficient and extensive functionality through a secure platform.`,
};
