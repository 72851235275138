import { useTranslation } from "react-i18next";
import { CommonContactUs } from "common/contactUs";
import "./style.scss";

export const Content = () => {
  // for translation
  const { t } = useTranslation("contactUs");

  return (
    <div className="Content">
      <div className="Content_header">
        <div className="Content_header_heading" data-aos="fade-up">
          <h2 className="Content_header_heading_text">{t("contactUs")}</h2>
          <div className="Content_header_heading_underline"></div>
        </div>
      </div>
      <div className="Content_contactUs">
        <CommonContactUs hideLabel={true} />
      </div>
    </div>
  );
};
