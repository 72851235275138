import useDocumentTitle from "utils/useDocumentTitle";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { refundPolicyProceduresDetails } from "data/refundPolicyProceduresDetails";
import { iconsPath } from "utils/iconsPath";
import toTitleCase from "utils/toTitleCase";

export const RefundPolicy = () => {
  // for translations
  const { t } = useTranslation([
    "refundPolicy",
    "refundPolicyProceduresDetails",
  ]);

  // To change page title
  useDocumentTitle(toTitleCase(t("refundPolicy")));

  return (
    <div className="RefundPolicy ">
      <div className="RefundPolicy_header container">
        <div className="RefundPolicy_header_heading " data-aos="fade-up">
          <h2 className="RefundPolicy_header_heading_text">
            {t("refundPolicy")}
          </h2>
          <div className="RefundPolicy_header_heading_underline"></div>
        </div>
        <h3 className="RefundPolicy_header_description " data-aos="fade-up">
          {t("refundPolicy_description")}
        </h3>
      </div>

      <div className="RefundPolicy_procedures container" data-aos="fade-up">
        {refundPolicyProceduresDetails.map((item, index) => {
          return (
            <div
              className="RefundPolicy_procedure"
              key={index}
              data-aos="fade-up"
            >
              <img
                src={iconsPath.RIGHT_ARROW}
                alt="arrow"
                className="RefundPolicy_procedure_arrow"
              />
              <p className="RefundPolicy_procedure_content">
                {t("refundPolicyProceduresDetails:" + item)}
              </p>
            </div>
          );
        })}
      </div>

      <div className="RefundPolicy_bottom container" data-aos="fade-up">
        <h3 className="RefundPolicy_bottom_description">
          {t("refundPolicy_bottom1")}
        </h3>
        <div className="RefundPolicy_bottom_separator"></div>
        <h3 className="RefundPolicy_bottom_description">
          {t("refundPolicy_bottom2")}
        </h3>
      </div>
    </div>
  );
};
