import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import "./style.scss";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useEffect, useRef, useState } from "react";
import i18n from "i18n";

/* Text Input */
export const TextInput = ({
  className,
  type = "text",
  label = "label",
  placeholder = "Placeholder text",
  errorMessage = "",
  maxLength,
  value,
  name,
  onChange,
}) => {
  return (
    <div className={`textInput ${className}`}>
      <label className="textInput_Label" htmlFor={label}>
        {label}
      </label>
      <br></br>
      <input
        className="textInput_Field"
        type={type}
        placeholder={placeholder}
        maxLength={maxLength}
        name={name}
        value={value}
        onChange={onChange}
      />
      <h6 className="textInput_Error">{errorMessage}</h6>
    </div>
  );
};

// Text Area Input
export const TextAreaInput = ({
  className,
  type = "text",
  label = "label",
  placeholder = "placeholder text",
  errorMessage = "",
  maxLength,
  value,
  name,
  onChange,
  rows = "5",
}) => {
  return (
    <div className={`textAreaInput ${className}`}>
      <label className="textAreaInput_Label " htmlFor={label}>
        {label}
      </label>
      <br></br>
      <textarea
        className="textAreaInput_Field"
        type={type}
        placeholder={placeholder}
        maxLength={maxLength}
        name={name}
        value={value}
        onChange={onChange}
        rows={rows}
      />
      <h6 className="textAreaInput_Error">{errorMessage}</h6>
    </div>
  );
};

/* Phone Input */
export const PhoneWithCountryInput = ({
  className,
  label = "label",
  value,
  errorMessage,
  onChange,
}) => {
  // To get phone container width, so that we can make drop down with same width as it's container
  const elementRef = useRef(null);
  const [elementWidth, setElementWidth] = useState(0);

  useEffect(() => {
    function calculateElementWidth() {
      if (elementRef.current) {
        const width = elementRef.current.clientWidth;
        setElementWidth(width);
      }
    }

    calculateElementWidth();

    window.addEventListener("resize", calculateElementWidth);

    return () => {
      window.removeEventListener("resize", calculateElementWidth);
    };
  }, []);

  if (document.body.dir === "rtl" || i18n.dir === "rtl") {
    var flag = document.querySelector(".flag");
    if (flag) {
      flag.style.right = "50%";
    }

    var textField = document.querySelector(".form-control");
    if (textField) {
      textField.style.textAlign = "right";
    }
  }

  return (
    <div className={`phoneInput ${className}`} ref={elementRef}>
      <label className="phoneInput_Label" htmlFor={label}>
        {label}
      </label>
      <br></br>
      <PhoneInput
        country={"ae"}
        value={value}
        onChange={onChange}
        containerClass={"phoneInput_Field"}
        inputClass={"phoneInput_Field_Input"}
        buttonClass={"phoneInput_Field_Button"}
        dropdownClass={"phoneInput_Field_Dropdown"}
        dropdownStyle={{ minWidth: `${elementWidth}px ` }}
      />
      <h6 className="phoneInput_Error">{errorMessage}</h6>
    </div>
  );
};

// Drop Down Menu
export function DropDownMenu({
  className,
  ListItems,
  label = "label",
  errorMessage = "",
  placeholder,
  value,
  onChange,
}) {
  return (
    <div className={`dropDownMenu ${className}`}>
      <label className="dropDownMenu_Label" htmlFor={label}>
        {label}
      </label>
      <br></br>
      <Dropdown
        options={ListItems}
        placeholder={placeholder}
        value={value}
        menuClassName={"dropDownMenu_Menu"}
        onChange={onChange}
      />
      <h6 className="dropDownMenu_Error">{errorMessage}</h6>
    </div>
  );
}
