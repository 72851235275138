import { Landing } from "./landing";
import { Content } from "./content";
import useDocumentTitle from "utils/useDocumentTitle";
import toTitleCase from "utils/toTitleCase";
import { useTranslation } from "react-i18next";

export const AboutUs = () => {
  // for translation
  const { t } = useTranslation("aboutUs");

  // To change page title
  useDocumentTitle(toTitleCase(t("aboutUs")));

  return (
    <div className="AboutUs">
      <Landing />
      <Content />
    </div>
  );
};
