export const cookieSlideDetails = [
  {
    heading: "cookiesPolicy_slide1_heading",
    description: "cookiesPolicy_slide1_description",
  },
  {
    heading: "cookiesPolicy_slide2_heading",
    description: "cookiesPolicy_slide2_description",
  },
  {
    heading: "cookiesPolicy_slide3_heading",
    description: "cookiesPolicy_slide3_description",
  },
  {
    heading: "cookiesPolicy_slide4_heading",
    description: "cookiesPolicy_slide4_description",
  },
  {
    heading: "cookiesPolicy_slide5_heading",
    description: "cookiesPolicy_slide5_description",
  },
  {
    heading: "cookiesPolicy_slide6_heading",
    description: "cookiesPolicy_slide6_description",
  },
];
