export const highlightSlideDetails_AR = {
  // Slide 1
  highlights_slide1_title: `ادخال بيانات`,
  highlights_slide1_description: `عامر لوجيستيكس هي شركة رائدة في تقديم الحلول اللوجستية لخدمات تسليم المستندات الفعالة التي تلبي احتياجات جميع العملاء من الأفراد والشركات. أقامت الشركة شراكات استراتيجية مع الوزارات والهيئات الحك`,

  // Slide 2
  highlights_slide2_title: `عملية`,
  highlights_slide2_description: `عامر لوجيستيكس هي شركة رائدة في تقديم الحلول اللوجستية لخدمات تسليم المستندات الفعالة التي تلبي احتياجات جميع العملاء من الأفراد والشركات. أقامت الشركة شراكات استراتيجية مع الوزارات والهيئات الحك`,

  // Slide 3
  highlights_slide3_title: `تتبع`,
  highlights_slide3_description: `عامر لوجيستيكس هي شركة رائدة في تقديم الحلول اللوجستية لخدمات تسليم المستندات الفعالة التي تلبي احتياجات جميع العملاء من الأفراد والشركات. أقامت الشركة شراكات استراتيجية مع الوزارات والهيئات الحك`,

  // Slide 4
  highlights_slide4_title: `تحويل`,
  highlights_slide4_description: `عامر لوجيستيكس هي شركة رائدة في تقديم الحلول اللوجستية لخدمات تسليم المستندات الفعالة التي تلبي احتياجات جميع العملاء من الأفراد والشركات. أقامت الشركة شراكات استراتيجية مع الوزارات والهيئات الحك`,
};
