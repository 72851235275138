export const goldenVisa_AR = {
  golden_visa: `الإقامة الذهبية`,
  golden_visa_section1_heading: `ما هي تأشيرة أبوظبي الذهبية؟`,
  golden_visa_section1_description: `إقامة أبوظبي الذهبية هي برنامج إقامة تقدمه حكومة أبوظبي، بالإمارات العربية المتحدة (أع م). وقد تم تصميم هذا البرنامج لاجتذاب المستثمرين الأجانب ورواد الأعمال والمهنيين ذوي المهارات العالية إلى الإمارة من خلال منحهم وضع الإقامة ومجموعة من المزايا الأخرى. 

ومن خلال إقامة أبوظبي الذهبية، يمكن للأفراد المؤهلين وعائلاتهم العيش والعمل في دولة الإمارات العربية المتحدة لمدة تصل إلى عشر سنوات، مع إمكانية التجديد. 

كما توفر الإقامة أيضًا مزايا مثل الوصول إلى الرعاية الصحية والتعليم وفرص الاستثمار. ويعد البرنامج جزءًا من الجهود الكبيرة التي تبذلها حكومة أبوظبي لتنويع الاقتصاد، وتعزيز التنافسية، واجتذاب المواهب الدولية إلى الإمارة. 

الحصول على الحصول على الإقامة الذهبية في أبوظبي قد يكون عملية معقدة، ولكن بمساعدة منصة مثل آمر، يمكن جعلها أكثر سهولة ويسرًا بكثير بالنسبة للأفراد المؤهلين.`,

  golden_visa_section2_heading: `Partnering up with the prestigious Government Entity`,
  golden_visa_section2_description: `نحن سعداء بالإعلان عن شراكتنا مع مكتب أبوظبي للمقيمين  لمساعدة العملاء المؤهلين في الحصول على الإقامة الذهبية.
بفضل هذه الشراكة، نحن الآن قادرون على تقديم خدمة مُخصصة ومميزة للعملاء المرشحين الراغبين في الحصول على الإقامة الذهبية لأبو ظبي.
نحن متحمسون لتقديم هذه الفرصة القيمة لعملائنا ونثق تمامًا أن العمل مع مكتب أبوظبي للمقيمين ستتيح لنا تقديم مستوى استثنائي من الخدمة.
نعتقد أن هذه الشراكة ستمكننا من إحداث تأثير كبير في حياة مواطني دولة الإمارات العربية المتحدة والمقيمين فيها.`,

  golden_visa_section3_heading1: `لماذا تختار آمر؟`,
  golden_visa_section3_description1: `آمر هي منصة رقمية مصممة لتسهيل حياة العملاء المتقدمين للحصول على الخدمات الحكومية المختلفة، حيث تقدم هذه المنصة حلًا سهل الاستخدام وفعال لأولئك الذين يسعون للحصول على الإقامة في دولة الإمارات العربية المتحدة والمزايا والمميزات المصاحبة لها.
مع آمر، يمكن للعملاء بسهولة اجتيازعملية تقديم الطلبات والوصول إلى المعلومات والمتطلبات المهمة وتلقي إرشادات ودعم الخبراء طوال العملية. ويكمن هدف آمر في الحصول على تجربة بسيطة وخالية من المتاعب للحصول على جميع الخدمات الحكومية. `,
  golden_visa_section3_heading2: `وإن شعار آمر هو "سجل دخولك للمنصة، واترك الباقي لنا"`,
  golden_visa_section3_description2: `وتتمثل رؤية آمر في إضفاء الطابع الرقمي على رحلة العميل عن طريق تحميل المستندات ببساطة في التطبيق ثم التكفل بالباقي من قبلنا. إن آمر هي المنصة المثالية لمساعدتك في تحقيق أهدافك وتحقيق تطلعاتك في الإمارات العربية المتحدة`,

  golden_visa_section5_heading: `التزامنا تجاه عملائنا`,
  golden_visa_section5_description: `1. تقديم خدمات متميزة وفق المعايير المعتمدة في المنصة.

2. تقديم الخدمات ومتطلباتها ومواعيد إنجازها وفق المعايير المعتمدة في المنصة.

3. تقديم معلومات دقيقة عن الخدمات والإجراءات المناسبة.

4. توفير إجراءات بسيطة وسريعة لخدمتك بسهولة.

5. إنشاء مركز اتصال متكامل للرد على جميع استفساراتك ومتابعة طلباتك والوفاء بها ضمن إطار محدد.

6. التعامل مع احتياجاتك بشكل احترافي وبذل قصارى جهدنا لتلبيتها بأعلى المعايير وضمن إطار محدد.

7. تقديم خدماتنا من خلال فريق عمل مطّلع يتفهم استفساراتك ويجيب عليها إلكترونيًا.`,

  golden_visa_section6_heading: `رسوم خدمة الإقامة الذهبية`,
  golden_visa_section6_title1: `الإقامة الذهبية`,
  golden_visa_section6_content1: `المستثمر والمستثمر في عقار`,
  golden_visa_section6_amount1: ` - 5,300 درهم`,
  golden_visa_section6_content2: `مقدم طلب آخر`,
  golden_visa_section6_amount2: ` - 4,750 درهم`,
  golden_visa_section6_title2: `الإقامة الذهبية – أعضاء الأسرة`,
  golden_visa_section6_content3: `داخل الإمارات العربية المتحدة`,
  golden_visa_section6_amount3: ` - 4,750 درهم`,
  golden_visa_section6_content4: `خارج الإمارات العربية المتحدة`,
  golden_visa_section6_amount4: ` - 4,200 درهم`,
  golden_visa_section6_content5: `التجديد داخل الإمارات العربية المتحدة`,
  golden_visa_section6_amount5: ` - 3,200 درهم`,
  golden_visa_section6_note: `* الرسوم أعلاه لا تشمل تغيير الحالة (أو تعديل الوضع) والخدمات الطبية.
* لاحظ أن ما ورد أعلاه من رسوم يشمل جميع الرسوم الحكومية وضريبة القيمة المضافة والرسوم المصرفية ورسوم آمر.
`,
};
