export const cookiesSlideDetails_EN = {
  // Slide 1
  cookiesPolicy_slide1_heading: `What happens when you visit the site?`,
  cookiesPolicy_slide1_description: `Each time you connect to the Internet, or if you use a permanent connection such as Broadband or Asymmetric Digital Subscriber Line (ADSL), when you start or restart the computer, you are automatically assigned a unique identification number known as the Internet Protocol (IP) address. This address contains information about the location of your computer on the Internet (your home country) and the name of your Internet service provider (ISP), and it is automatically registered by our website.`,

  // Slide 2
  cookiesPolicy_slide2_heading: `What is an IP address?`,
  cookiesPolicy_slide2_description: `When you first start your Internet session (that is, when your computer is connected to the Internet), a unique number is assigned to your computer automatically (usually within a range of nine or ten decimal numbers), known as the Internet Protocol (IP) address. This is the unique address of your computer on the Internet. Without this IP address, websites will not be able to deliver their content to you because they will not be able to find your computer on the Internet. Each time you disconnect and reconnect to the Internet, a new IP address is automatically assigned to your computer. As such, IP addresses are not inherently able to identify you as an individual (at least, on their own). However, the IP address contains information about the location of your computer on the Internet (your country) and the name of your Internet service provider (ISP).`,

  // Slide 3
  cookiesPolicy_slide3_heading: `How do we collect your IP address?`,
  cookiesPolicy_slide3_description: `Every time you visit a page on our website, your computer sends a message to our website requesting the content. The message that your computer sends also includes your Internet Protocol (IP) address as a form of return address so that the website can find your computer to send the content you requested by clicking on the page. Our web server automatically records all of these messages.`,

  // Slide 4
  cookiesPolicy_slide4_heading: `What do we do with your IP address?`,
  cookiesPolicy_slide4_description: `When we register your IP address, the data collected with other registered IP addresses are collected to provide us with geo-location statistics for visitors to our site, how long they remain on our site, which pages are more viewed, and other statistical reasons. We automatically collect your IP address and store it in log files. These files also contain information about your browser type, Internet service provider (ISP), operating system, date and time stamp, click-through data, and files that are displayed on our website. Collecting this type of information allows us to create aggregated information for our website, including general user trends and online activities (such as the number of unique visitors, frequently accessed pages, or search terms entered). It also allows us to manage our website, diagnose any potential server issues, analyze visitor trends and data, and generally help us provide you with a better Internet experience. For these reasons, IP addresses are not stored for too long.`,

  // Slide 5
  cookiesPolicy_slide5_heading: `Information about our use of cookies`,
  cookiesPolicy_slide5_description: `Our website uses cookies to distinguish you from other users of our site. Cookies allow us to analyze trends and manage the site to help us deliver a good experience when you browse and improve our website at the same time. A cookie is a small file of information (letters and numbers) that we store on your browser or on your computer's hard drive if you agree. A cookie enables the website to remember users who have already visited it. Without a cookie, each time you open a new webpage, the server where that page is stored will take you as a completely new visitor. Cookies allow us to remember your custom preferences, such as your language preferences, and allow you to complete tasks without having to re-enter your information.`,

  // Slide 6
  cookiesPolicy_slide6_heading: `Smart and Secure E-services in The UAE`,
  cookiesPolicy_slide6_description: `E-services, Smart Digital Platform, Judicial Department, Golden Visa, Commercial Licenses, Municipalities & Transport, Domestic Worker, Documents Translation & Attestation, Immigration & ID, Business Administrative Services, Human Resources, Logistics, Documents Pick-up & Delivery in UAE.
Aamer Group offers E-services, Smart Digital Platform, Commercial Licenses, Judicial Department, Golden Visa, Human Resources, Business Administrative Services, Logistics, Documents Pick-up & Delivery, Documents Translation & Attestation, Domestic Worker, Immigration & ID in UAE
`,
};
