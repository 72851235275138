import { PrimaryButton } from "common/buttons";
import { useTranslation } from "react-i18next";
import { useWindowDimensions } from "utils/useWindowDimensions";
import { companyDetails } from "../../../data/companyDetails";
import "./style.scss";

export const OurCompanies = () => {
  // for translation
  const { t } = useTranslation(["home", "companyDetails"]);

  // To show x numbers of slides based on window width
  const { width } = useWindowDimensions();
  function getSlidePerView() {
    let slidePerView;
    if (width <= 768) {
      slidePerView = 1;
    } else if (width <= 1100) {
      slidePerView = 2;
    } else {
      slidePerView = 3;
    }
    return slidePerView;
  }

  return (
    <div className="OurCompanies container">
      <div className="OurCompanies_heading" data-aos="fade-up">
        <h2 className="OurCompanies_heading_text">
          {t("our_companies_heading")}
        </h2>
        <div className="OurCompanies_heading_underline"></div>
      </div>
      <swiper-container
        class="OurCompanies_slider "
        pagination="true"
        pagination-clickable="true"
        pagination-dynamic-bullets="true"
        slides-per-view={getSlidePerView()}
        auto-height="true"
        space-between="15"
        grab-cursor="true"
        autoplay-delay="5000"
        speed="1000"
        autoplay-disable-on-interaction="false"
      >
        {companyDetails.map((item, index) => {
          return (
            <swiper-slide class="OurCompanies_slider_slide" key={index}>
              <Company
                name={t("companyDetails:" + item.name)}
                logo={item.logo}
                description={t("companyDetails:" + item.description)}
                path={item.path}
                buttonText={t("learn_more")}
              />
            </swiper-slide>
          );
        })}
      </swiper-container>
    </div>
  );
};

// Common component for company details
const Company = ({ name, logo, description, path, buttonText }) => {
  return (
    <div className="Company" data-aos="fade-right">
      <img className="Company_image" src={logo} alt={name} data-aos="fade-up" />
      <p className="Company_paragraph" data-aos="fade-up">
        {description}
      </p>
      <PrimaryButton
        title={buttonText}
        path={path}
        className={"Company_button"}
        data_aos="fade-up"
      />
    </div>
  );
};
