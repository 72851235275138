export const imagesPath = {
  // common

  // common > background patterns
  BACKGROUND_PATTERN: require("assets/images/common/patterns/background-pattern-dark.png"),
  BACKGROUND_PATTERN_LIGHT: require("assets/images/common/patterns/background-pattern-light.png"),

  // pages > home
  HOME_ABOUT_US: require("assets/images/pages/home/about-us/background.jpg"),
  HOME_APPLICATION: require("assets/images/pages/home/application/banner.png"),

  // pages > about us
  ABOUT_US: require("assets/images/pages/about-us/about-us.png"),
  OUR_VISION: require("assets/images/pages/about-us/our-vision.jpg"),
  OUR_GOAL: require("assets/images/pages/about-us/our-goal.jpg"),
  OUR_VALUES: require("assets/images/pages/about-us/our-values.jpg"),
  OUR_MISSION: require("assets/images/pages/about-us/our-mission.jpg"),

  // pages > our-services > golden visa
  GOLDEN_VISA_SECTION4: require("assets/images/pages/golden-visa/golden-visa-section4.jpg"),
};
