import i18n from "i18n";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { iconsPath } from "utils/iconsPath";
import "./style.scss";

export const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [logoVisibility, setLogoVisibility] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("selectedLanguage")
  );

  const navbarRef = useRef(null);

  // [1.] To toggle navbar
  const toggleNav = () => {
    setToggleMenu(!toggleMenu);
  };

  // [2.] To show logo after some vertical scroll
  const getScrollPosition = () => {
    var verticalScrollPosition = window.scrollY;
    if (verticalScrollPosition > 250) {
      setLogoVisibility(true);
    } else {
      setLogoVisibility(false);
    }
  };

  window.addEventListener("scroll", getScrollPosition);

  // [3.] To close navbar while clicking on other sections other than navbar
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setToggleMenu(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  // [4.] To change language, set changed language to local storage and change LTR or RTL layout based on selected language.
  function toggleLanguage(language) {
    i18n.changeLanguage(language);
    localStorage.setItem("selectedLanguage", language);
    setSelectedLanguage(language);
    document.body.dir = i18n.dir();
    window.location.reload();
  }

  return (
    <div
      className="Navbar"
      data-aos="fade-down"
      data-aos-delay="1000"
      onScroll={getScrollPosition}
      ref={navbarRef}
    >
      {/* menu */}
      <div
        className="Navbar_header"
        style={
          toggleMenu
            ? { backgroundColor: " rgba(255, 255, 255, 0)" }
            : { backgroundColor: " rgba(255, 255, 255, 0)" }
        }
      >
        <Link to="/">
          <img
            className={
              toggleMenu
                ? "Navbar_header_logo_show"
                : logoVisibility
                ? "Navbar_header_logo_show"
                : "Navbar_header_logo_hide"
            }
            src={iconsPath.LOGO_RED_WITHTEXT}
            alt="aamer group"
          />
        </Link>
        <div className="Navbar_header_rightSection ">
          <div className="Navbar_header_rightSection_toggleLanguage ">
            <h3
              onClick={() => toggleLanguage("en")}
              className={
                selectedLanguage === "en"
                  ? "Navbar_header_rightSection_toggleLanguage_selected"
                  : ""
              }
            >
              EN
            </h3>
            <div className="Navbar_header_rightSection_toggleLanguage_separator"></div>
            <h3
              onClick={() => toggleLanguage("ar")}
              className={
                selectedLanguage === "ar"
                  ? "Navbar_header_rightSection_toggleLanguage_selected"
                  : ""
              }
            >
              AR
            </h3>
          </div>
          <div
            className="Navbar_header_rightSection_toggleDrawer"
            onClick={toggleNav}
          >
            {!toggleMenu ? (
              <img
                src={iconsPath.MENU}
                className="Navbar_header_rightSection_toggleDrawer_open"
                alt=""
              ></img>
            ) : (
              <img
                src={iconsPath.CLOSE}
                alt=""
                className="Navbar_header_rightSection_toggleDrawer_close"
              ></img>
            )}
          </div>
        </div>
      </div>

      {/* drawer */}
      {toggleMenu && (
        <Drawer
          onClick={() => {
            toggleNav();
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        />
      )}
    </div>
  );
};

const Drawer = ({ onClick }) => {
  // for translation
  const { t } = useTranslation("navbar");

  return (
    <div className="Drawer">
      <div className="Drawer_separator"></div>
      <div className="Drawer_links" onClick={onClick}>
        {/* home */}
        <Link to="/" className="Drawer_links_link">
          {t("home")}
        </Link>

        <div className="Drawer_links_separator"></div>

        {/* about us */}
        <Link to="/about-us" className="Drawer_links_link">
          {t("about_us")}
        </Link>

        <div className="Drawer_links_separator"></div>

        {/* our companies */}
        <Link to="/our-companies" className="Drawer_links_link">
          {t("our_companies")}
        </Link>

        <div className="Drawer_links_separator"></div>

        {/* our services */}
        <Link to="/our-services" className="Drawer_links_link">
          {t("our_services")}
        </Link>

        <div className="Drawer_links_separator"></div>

        {/* our partners */}
        <Link to="/our-partners" className="Drawer_links_link">
          {t("our_partners")}
        </Link>

        <div className="Drawer_links_separator"></div>

        {/* aamer platform */}
        {/* <a href="/aamer-platform" className="Drawer_links_link">
          {t("aamer_platform")}
        </a> */}

        {/* <div className="Drawer_links_separator"></div> */}

        {/* blog */}
        {/* <a href="/blogs" className="Drawer_links_link">
          {t("blogs")}
        </a> */}

        {/* <div className="Drawer_links_separator"></div> */}

        {/* contact us */}
        <Link to="/contact-us" className="Drawer_links_link">
          {t("contact_us")}
        </Link>
      </div>
    </div>
  );
};
