export const serviceDetails_EN = {
  golden_visa: `golden visa`,
  golden_visa_shortDescription: `What is Abu Dhabi Golden Visa?
The Abu Dhabi Golden Visa is a residency program offered by the government of Abu Dhabi, United Arab Emirates (UAE). It is designed to attract foreign investors, entrepreneurs, and highly skilled professionals to the emirate by offering them residency status and a range of benefits.`,
  golden_visa_longDescription: `With the Abu Dhabi Golden Visa, eligible individuals and their families can live and work in the UAE for a period of up to ten years, with the possibility of renewal.

The visa also provides benefits such as access to healthcare, education, and investment opportunities. The program is part of the Abu Dhabi government's larger efforts to diversify the economy, enhance competitiveness, and attract international talent to the emirate.

Obtaining the Abu Dhabi Golden Visa can be a complex process, but with the help of a platform like Aamer, it can be made much easier and more accessible for eligible individuals.`,

  translation: `translation`,
  translation_shortDescription: `What is Legal translation?
The legal translation is the translation of texts in the field of law from foreign languages ​​to Arabic and vice versa.`,
  translation_longDescription: `Why there is need for legal translation UAE?
Because the official language in the UAE is the Arabic language, and to complete any official legal transactions, the required documents must be legally translated from the original language into Arabic, and then this translation is approved by the legal specialists, and the legal judgment and decision is issued that this translation is acceptable to the legal texts and it is correct work with it.
  
The document must be translated in bodies accredited by government departments, ministries, and courts.

Some of the types of documents that need translation based on the services and needs served by certificates (scientific certificates, marriage, birth, death, etc.) and power of attorney.

Aamer provides legal translation service, where the translation is done by legally specialized translators accredited by the government agencies in the UAE, and the service begins immediately after receiving the documents, and the transaction is tracked by us.`,

  attestations: `attestations`,
  attestations_shortDescription: `What is the attestation process of official documents and certificates?
Attestation is a procedure to confirm validity of the stamp and signature on documents issued in the UAE or abroad.`,
  attestations_longDescription: `Importance of the certification process in the UAE?
The process of attesting university degrees and personal or business documents obtained or carried out outside the UAE is important in order for you to be able to use them legally in the UAE, and to be able to apply for a job or use them for other legal purposes.

And since the certification of documents is very important for the document to be official, legal and free from any errors, we Aamer are committed to cooperating with the competent authorities to make the process of document certification easier and faster.

Documents that require attestation are divided into two types:
Commercial documents for example but not limited to (company contracts - agencies - licenses - partnership contracts - founding contracts - contract or agreement - commercial register - change of company name - trademark registration - corporate insurance certificate - corporate tax certificate - trade license - patent registration or waiver - a general power of attorney or any other document of a commercial nature) which must be notarized at the UAE Embassy of that country, after which it can be legalized at the UAE Ministry of Foreign Affairs.

Personal status documents such as (marriage contracts - university certificates - birth certificates - death certificates - medical reports - certificate of good conduct - certificates issued by the court - personal power of attorney - certificate to whom it may concern - work contract - employment offer - bank statements - tax certificate character and others.
 
Special requirements -
1. Attest the certificate from UAE Embassy of the certificate issued country.
2. Attest the certificate from the Ministry of Foreign Affairs of the home country (issued place).`,

  commercial_license: `commercial license`,
  commercial_license_shortDescription: `The UAE seeks to meet the different needs of the citizen and resident and facilitates many processes related to the development of individual businesses, and works to manage economic initiatives in order to enhance the economy of the capital, Abu Dhabi, by supporting various commercial activities and providing a wide range of services related to economic licenses.`,
  commercial_license_longDescription: `Perhaps the services of the Department of Economic Development in Abu Dhabi are among the most important institutions that the population may need. Therefore, Aamer provided services for those wishing to work and issued an independent work permit in the UAE in general and Abu Dhabi in particular, which is the free professions license, which allows individuals to do various freelance jobs in Abu Dhabi, without the restrictions of companies that require employees to take specific measures, It is also possible to submit a request to renew the Abu Dhabi trade license and reserve a trade name in an easy and convenient way through the Aamer application.

The service of applying for a self-employment license in the UAE (Freelance work permit license) is also available in Aamer with ease and speed, and it is one of the much-needed services in recent times that keep pace with the technological development in the UAE, where many companies have converted their business to the electronic domain, and that Because of the advantages and benefits it provides, which work to transform the nature of work from tangible to visible and attract the largest number of clients and customers. It also allows individuals, both citizens and residents, to benefit from their talents and harness them in the labor market.

One of the most important advantages of a self-employment license in the UAE is that it does not require the availability of an actual headquarters for the company, as self-employment can be done anywhere, depending on the nature of the work. It also gives freedom to self-employed owners, as they can work at any time as well as it suits them. Likewise, the holder of a self-employed license in Abu Dhabi does not need a local partner or a local service agent.`,

  municipalities_and_transport: `municipalities and transport`,
  municipalities_and_transport_shortDescription: `One of the services and legal procedures for real estate transactions that Aamer facilitated for clients in order to save effort and money is the renewal of the lease contract.`,
  municipalities_and_transport_longDescription: `The lease contract guarantees openness and transparency between the landlord and the tenant, the integration of all lease contracts into the legal system, easy review of contracts in the event of a dispute between the two parties, and the guarantee of their rights in the event of any dispute.

The lessor can apply for the service when needed to renew a previously registered lease contract and obtain the contract directly from the system or from the tenant provided that the approval of the lessor is obtained. After submitting the application from the tenant to obtain the contract from the system.`,

  delivery_services: `delivery services`,
  delivery_services_shortDescription: `Aamer application provides the delivery service from the pickup and delivery of completed documents to companies and individuals alike, as we seek to provide the best solutions and ways to complete, clear, receive and deliver all official documents on behalf of customers, which contributes to saving time using an easy procedure that allows customers to submit their documents wherever they are. `,
  delivery_services_longDescription: `  The company focuses on ensuring customer comfort and happiness, saving time and effort, while ensuring the confidentiality of customer information using the secure system.`,

  human_resources: `human resources`,
  human_resources_shortDescription: `In accordance with the law of the United Arab Emirates on regulating labor relations, known as the "UAE Labor Law", residency is a prerequisite for all expatriates who wish to work or live in the United Arab Emirates and it is not permissible to work in the country unless after obtaining a work permit moreover, it is a requirement to obtain many vital services, such as opening a bank account or obtaining a driver's license and others. A work permit and residence permit is obtained from the Ministry of Human Resources and Emiratization.`,
  human_resources_longDescription: `In line with the strategy of the United Arab Emirates for digital government services, and the policy of the unified digital platform, to provide advanced digital services and anywhere, Aamer Group launched its application, "Aamer App", to manage and transfer digital documents in the Emirate of Abu Dhabi, including Human Resources Services.

We also provide the service of issuing the employee's work contract according to one of the approved work patterns, as well as providing the UAE residency package (outside and inside the country) and open company file to facilitate the labor procedures.

Terms and Conditions may apply.`,

  id_and_immigration: `ID & Immigration`,
  id_and_immigration_shortDescription: "",
  id_and_immigration_longDescription: `Aamer provides a variety of electronic integrated services related to immigration and ID in Aamer application to facilitate and speed up the completion of visa and residency transactions for the government and private sectors, in addition to issuing the Emirates ID and facilitating it for individuals and companies in Abu Dhabi in order to save time and effort for everyone.`,

  international_visas: `international visas`,
  international_visas_shortDescription: `Multiple-Entry Tourist Visa
The Aamer platform allowed the public through its system of smart services to apply for a multi-entry tourist visa for a family group with children (under 18 years), for a period of five years, and it is issued to all nationalities without a sponsor.`,
  international_visas_longDescription: `This visa allows the beneficiary to enter the UAE more than once without the need for a guarantor or host, and to stay inside the country for 90 days on each visit so that the total of visit does not exceed 180 consecutive days per year, on the condition that to enter the country within 60 days from the date of issuance of the entry permit to avoid canceling it.

The customer can apply for the visa through Aamer application and obtain it electronically within one working day from the date of applying for it and fulfilling the requirements.

To obtain this visa, the applicant must have:
1. bank balance of 4,000 USD or its equivalent in foreign currencies during the last six months
2. valid health insurance policy applicable in the UAE
3. ticket to and from the UAE
proof of stay (hotel/residential address) in the UAE.`,

  domestic_workers: `domestic workers`,
  domestic_workers_shortDescription: `If you employ domestic workers to work in the United Arab Emirates, you must apply for a work permit according to the UAE law regarding domestic workers, which ensures that domestic workers are aware of the terms of the contract, nature of work, the workplace, the remuneration and the period of daily and weekly rest as set out by the executive regulations and before they cross their national borders.`,
  domestic_workers_longDescription: `The Aamer application provides a service for providing a work permit for domestic workers for UAE Nationals, GCC and valid UAE by facilitating the request electronically and ensuring that it is followed up and completed.

Apply the Regulations on the employment of domestic workers in the UAE.`,

  judicial_department: `judicial department`,
  judicial_department_shortDescription: `Justice is the basis of governance, and the progress of societies can only be achieved through justice, equality, and equal opportunities, and the role of the Judicial Department is the rule of law by ensuring justice, safeguarding rights, freedoms, and the security of society.`,
  judicial_department_longDescription: `In view of the rapid development that the Emirate of Abu Dhabi is going through and the influx of regional and global investments into it, Judicial services have become based on a high-quality digital system to ensure customer service in the implementation of judicial procedures.

Aamer is a digital platform designed to facilitate customer service by smoothing the provision of various judicial services to the Court in the capital Abu Dhabi (Abu Dhabi, Al Ain and the Western Region) and ensuring high quality, efficient and hassle-free service, only the customer has to upload the documents in the application and then we take care of the rest.`,
};
