import { iconsPath } from "utils/iconsPath";
import "./style.scss";

export const Loader = () => {
  return (
    <div className="Loader">
      <img
        className="animate__animated animate__pulse animate__infinite"
        src={iconsPath.LOGO_RED_WITHTEXT}
        alt="loader"
      />
    </div>
  );
};
