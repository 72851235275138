import "./style.scss";
import { imagesPath } from "utils/imagesPath";

export const Application = () => {
  return (
    <div className="Application">
      <img
        className="Application_image"
        src={imagesPath.HOME_APPLICATION}
        alt="Aamer Application"
      />
    </div>
  );
};
