import { useTranslation } from "react-i18next";
import "./style.scss";
import { Link } from "react-router-dom";

export const Portal = () => {
  // for translation
  const { t } = useTranslation("home");

  return (
    <div className="Portal" data-aos="fade-up">
      <Link
        to={"https://aamercustomerportal.azurewebsites.net"}
        target="_blank"
        className="Portal_heading"
        data-aos="fade-up"
      >
        <h1 className="Portal_heading_text" data-aos="fade-up">
          {t("portal_heading")}
        </h1>
      </Link>
      <p className="Portal_paragraph" data-aos="fade-up">
        {t("portal_description")}
      </p>
    </div>
  );
};
