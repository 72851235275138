export const cookiesPolicy_AR = {
  cookies_policy: `سياسة ملفات الارتباط`,
  cookiesPolicy_description: `يرجى قراءة السياسة التالية بعناية لفهم كيف ولماذا يستخدم هذا الموقع عنوان بروتوكول الانترنت الخاص بك - المشار إليه فيما بعد بعنوان بروتوكول الانترنت و / أو (IP) - وملفات تعريف الارتباط - ويشار إليها فيما بعد باسم ملف تعريف الارتباط و / أو (ملفات تعريف الارتباط). 
  
  يجب أن توافق صراحةً على الوصول إلى وتصفح منصة آمر الرقمية و / أو تطبيق آمر (https://www.aamerhub.ae) - المشار إليه فيما بعد باسم "الموقع" و / أو منصة آمر الرقمية.
  
  إذا كنت لا تقبل شروط سياسة ملفات تعريف الارتباط (ملفات تعريف الارتباط) هذه، فيجب عليك الخروج من الموقع على الفور. تجمع منصة آمر الرقمية - ويمكن الإشارة إلينا بالضمير المتصل (نا ، أو آمر) وبنوعين من بياناتك الناتجة عن استخدامك للموقع ، وهي المعلومات شخصية ، بما في ذلك اسمك وعنوان بريدك الإلكتروني ،والمعلومات الكاملة - الناتجة عن الممارسات التي نطبقها فيما يتعلق بملفات تعريف الارتباط وعنوان بروتوكول الانترنت لمستخدمي الويب.
  
  تم تحديد ممارساتنا لجمع هذه المعلومات الشخصية ومعالجتها في سياسة الخصوصية الخاصة بنا في المقابل، توصف ممارساتنا لإنشاء معلومات مجمعة (كما هو مذكور أعلاه) في سياسة ملفات تعريف الارتباط هذه. من أجل تصفح واستخدام موقعنا ، وكذلك الاتصال عبر الإنترنت عبر المنصة التي تستخدمها ، يجب أن توافق صراحة على الممارسات المنصوص عليها في هذه السياسة.`,

  // Slide 1
  cookiesPolicy_slide1_heading: `ماذا يحدث عند زيارة الموقع؟`,
  cookiesPolicy_slide1_description: `في كل مرة تتصل فيها بالإنترنت ، أو إذا كنت تستخدم اتصالاً دائمًا مثل النطاق العريض أو خط المشترك الرقمي غير المتماثل (ADSL) ، عند بدء تشغيل الحاسوب أو إعادة تشغيله ، فإنك تقوم تلقائيًا بتعيين رقم تعريف فريد يُعرف باسم بروتوكول الإنترنت ( IP) لهذا العنوان الذي يحتوي على معلومات حول موقع جهاز الحاسوب الخاص بك على الإنترنت (بلدك) واسم مزود خدمة الإنترنت (ISP) ، ويتم تسجيل كل ذلك تلقائيًا بواسطة موقعنا على الويب.`,

  // Slide 2
  cookiesPolicy_slide2_heading: `ما هو عنوان بروتوكول الانترنت`,
  cookiesPolicy_slide2_description: `عند بدء جلسة الإنترنت لأول مرة (أي عندما يكون جهاز الحاسوب الخاص بك متصلاً بالإنترنت) ، يتم تعيين رقم فريد لجهاز الحاسوب الخاص بك تلقائياً (عادةً في نطاق تسعة أو عشرة أرقام عشرية) ، والمعروف باسم بروتوكول الإنترنت ( IP) ، هذا هو العنوان الفريد لجهاز الحاسوب الخاص بك على الإنترنت. بدون عنوان بروتوكول الانترنت هذا ، لن تتمكن مواقع الويب من تسليم محتواها إليك لأنها لن تكون قادرة على العثور على جهاز الحاسوب الخاص بك على الإنترنت ، لأنه في كل مرة تقوم فيها بقطع الاتصال بالإنترنت وإعادة الاتصال به ، يتم تعيين عنوان بروتوكول الانترنت جديد تلقائيًا لحاسوبك. على هذا النحو ، لا تستطيع عناوين بروتوكول الانترنت بطبيعتها التعرف عليك كفرد (على الأقل ، بمفردها). ومع ذلك ، يحتوي عنوان بروتوكول الانترنت على معلومات حول موقع جهاز الحاسوب الخاص بك على الإنترنت (بلدك) واسم مزود خدمة الإنترنت (ISP).`,

  // Slide 3
  cookiesPolicy_slide3_heading: `كيف نحصل على عنوان بروتوكول الانترنت الخاص بك؟`,
  cookiesPolicy_slide3_description: `في كل مرة تزور فيها صفحة على موقعنا ، يرسل جهاز الحاسوب الخاص بك رسالة إلى موقعنا لطلب المحتوى. تتضمن الرسالة التي يرسلها جهازك أيضًا عنوان بروتوكول الإنترنت (IP) الخاص بك كشكل من أشكال عنوان الإرجاع حتى يتمكن موقع الويب من العثور على جهاز الحاسوب الخاص بك لإرسال المحتوى الذي طلبته بالنقر فوق الصفحة. يقوم خادم الويب الخاص بنا بتسجيل جميع هذه الرسائل تلقائياً.`,

  // Slide 4
  cookiesPolicy_slide4_heading: `ماذا نفعل بعنوان بروتوكول الانترنت الخاص بك؟`,
  cookiesPolicy_slide4_description: `عندما نقوم بتسجيل عنوان بروتوكول الانترنت الخاص بك ، فإنه يتم استخدام البيانات ذات الصلة بعناوين بروتوكول الانترنت المسجلة الأخرى من أجل تزويدنا بإحصائيات الموقع الجغرافي لزوار موقعنا ، ومدة بقائهم على موقعنا ، والصفحات التي يتم عرضها بشكل أكبر ، وغير ذلك من الأسباب الإحصائية. نقوم تلقائياً بجمع عنوان بروتوكول الانترنت الخاص بك وتخزينه في ملفات السجل. تحتوي هذه الملفات أيضاً على معلومات حول نوع المستعرض الخاص بك ومزود خدمة الإنترنت (ISP) ونظام التشغيل وطابع التاريخ والوقت وبيانات النقر والملفات التي يتم عرضها على موقعنا على الويب.يتيح لنا جمع هذا النوع من المعلومات إنشاء معلومات مجمعة لموقعنا على الويب ، بما في ذلك اتجاهات المستخدم العامة والأنشطة عبر الإنترنت (مثل عدد الزوار الفريدين أو الصفحات التي يتم الوصول إليها بشكل متكرر أو مصطلحات البحث التي تم إدخالها). كما يتيح لنا إدارة موقعنا على الويب ، وتشخيص أي مشكلات محتملة في الخادم ، وتحليل اتجاهات وبيانات الزائرين ، ومساعدتنا بشكل عام على تزويدك بتجربة إنترنت أفضل ، ولهذه الأسباب لا يتم تخزين عناوين بروتوكول الانترنت لفترة طويلة جدًا.`,

  // Slide 5
  cookiesPolicy_slide5_heading: `معلومات حول استخدامنا لملفات تعريف الارتباط`,
  cookiesPolicy_slide5_description: `يستخدم موقعنا الإلكتروني ملفات تعريف الارتباط لتمييزك عن المستخدمين الآخرين لموقعنا. تسمح لنا ملفات تعريف الارتباط بتحليل الاتجاهات وإدارة الموقع لمساعدتنا على تقديم تجربة جيدة عند تصفح موقعنا وتحسينه في نفس الوقت. ملف تعريف الارتباط هو ملف صغير من المعلومات (أحرف وأرقام) نقوم بتخزينه على متصفحك أو على محرك الأقراص الثابتة بجهاز الحاسوب الخاص بك إذا كنت توافق على ذلك. يُمكّن ملف تعريف الارتباط موقع الويب من تذكر المستخدمين الذين قاموا بزيارته بالفعل. بدون ملف تعريف ارتباط فإنه في كل مرة تفتح فيها صفحة ويب جديدة ، سيأخذك الخادم حيث يتم تخزين هذه الصفحة كزائر جديد تماماً. تتيح لنا ملفات تعريف الارتباط تذكر تفضيلاتك المخصصة ، مثل تفضيلات اللغة الخاصة بك ، وتسمح لك بإكمال المهام دون الحاجة إلى إعادة إدخال معلوماتك`,

  // Slide 6
  cookiesPolicy_slide6_heading: `خدمات إلكترونية ذكية وآمنة في الإمارات العربية المتحدة`,
  cookiesPolicy_slide6_description: `الخدمات الإلكترونية ، المنصة الرقمية الذكية ، الدائرة القضائية ، الإقامة الذهبية ، التراخيص التجارية ، البلديات والنقل ، العمالة المساعدة ، ترجمة المستندات والتصديق عليها ، الهجرة والهوية ، الخدمات الإدارية للأعمال ، الموارد البشرية ، الخدمات اللوجستية ، استلام المستندات وتسليمها في الإمارات العربية المتحدة
تقدم  مجموعة آمر الخدمات الإلكترونية ، والمنصة الرقمية الذكية ، والتراخيص التجارية ، والدائرة القضائية ، والإقامة الذهبية ، والموارد البشرية ، والخدمات الإدارية للأعمال ، والخدمات اللوجستية ، واستلام المستندات وتسليمها ، وترجمة المستندات والتصديق عليها ، والعمالة المساعدة ، والهجرة والهوية في الإمارات العربية المتحدة`,
};
