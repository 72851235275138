export const cookiesPolicy_EN = {
  cookies_policy: `cookie policy`,
  cookiesPolicy_description: `Please read the following policy carefully to understand how and why this site uses your IP address – hereinafter referred to as IP address and/or (IP) - and cookies – hereinafter referred to as cookie and/or (cookies). 
  
    You must expressly consent to access and browse the Aamer Digital Hub Platform and/or AAMER application (https://www.aamerhub.ae) site – hereinafter referred to as the "site" and/or Aamer Digital Hub Platform.
    
    If you do not accept the terms of this cookie (cookies) policy, you must exit the site immediately. The Aamer Digital Hub Platform collects – and can be referred to as (us, our, or AAMER) - two types of information about you resulting from your use of the Site, which are personal information, including your name and email address, and full information – generated from the practices we apply with respect to cookies and the IP address of web users.
    
    Our practices for collecting and processing this personal information are defined in our Privacy Policy. In contrast, our practices for creating aggregate information (as mentioned above) are described in this cookie policy. In order to browse and use our site, as well as the online platform to which you are connecting through the website, you must explicitly agree to the practices set forth herein.`,
};
