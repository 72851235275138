export const navbar_AR = {
  home: `الرئيسية`,
  about_us: `من نحن`,
  our_companies: `شركاتنا`,
  our_partners: `شركاؤنا`,
  aamer_platform: `منصة عامر`,
  blogs: `المدونات`,
  contact_us: `اتصل بنا`,
  our_services: `خدماتنا`,
};
