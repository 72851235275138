import "./style.scss";
import { imagesPath } from "utils/imagesPath";
import { iconsPath } from "utils/iconsPath";

export const Landing = () => {
  return (
    <div
      className="landing"
      style={{ backgroundImage: `url(${imagesPath.BACKGROUND_PATTERN})` }}
    >
      <img
        className="landing_image"
        data-aos="zoom-in"
        src={iconsPath.LOGO_RED_WITHTEXT}
        alt="Aamer Group"
      />
    </div>
  );
};
