export const ourCompanies_EN = {
  our_companies: `our companies`,

  // [1.] Aamer Consultancy
  aamer_consultancy_description: `Aamer Consultancy was established to provide a wide range of consultancy services that encompass logistics, human resources, public relations, project development, project management, and administrative activities. The company endeavors to meet the requirements of customers, exceed their expectations, follow the best international practices and standards, and adopt cutting-edge technology in the delivery of its services.`,

  // [2.] Aamer Logistics
  aamer_logistics_description: `Aamer Logistics is a leading provider of logistics solutions for efficient document delivery services that cater to the needs of all individual and corporate clients. The company has built strategic partnerships with ministries, governmental and semi-governmental entities, and companies to create a unified platform that ensures the smooth delivery of all types of documents while keeping pace with the visions and aspirations of the government of the United Arab Emirates. This guarantees customer happiness and ensures fast and high-quality document delivery services.`,

  // [3.] Aamer Business
  aamer_business_description: `Aamer Business is the ultimate business company that specializes in administrative services for businessmen. It includes business facilities that provide the necessary secretarial services and management.`,

  // [4.] Aamer Delivery
  aamer_delivery_description: `Aamer Delivery is a one-stop center specializing in receiving and delivering finalized documents for companies and individuals alike. Aamer Delivery aims to provide the best solutions and approaches for the completion, clearance, collection, and delivery of all government documents on behalf of customers while saving time with an effortless process, allowing customers to submit their documents from wherever they are. The company focuses on the following strategies: guaranteeing customers' comfort and happiness, speed: saving time and effort, and privacy: ensuring the confidentiality of customers' information by using a secure system.`,

  // [4.] Aamer Digital Hub
  aamer_digitalHub_description: `Aamer Digital Hub Platform is a distinguished IT company that develops advanced software solutions and Aamer Digital Hub platforms targeting various markets. The company created the Aamer Application platform, a smart services portal, and an operating call center that combines the private and government sectors, offering outstanding quality services for customers. Its real-time value-added solutions are designed to provide efficient and extensive functionality through a secure platform.`,
};
