export const ourCompanies_AR = {
  our_companies: `شركاتنا`,

  // [1.] Aamer Consultancy
  aamer_consultancy_description: `تأسست آمر للاستشارات لتوفير مجموعة متنوعة من الخدمات الاستشارية التي تشمل الخدمات اللوجستية وخدمات الموارد البشرية وخدمات العلاقات العامة وخدمات تطوير المشاريع وإدارتها والأنشطة الإدارية. تسعى الشركة لتلبية متطلبات العملاء و تجاوز توقعاتهم والامتثال لأفضل الممارسات والمعايير الدولية واستخدام أحدث التقنيات في تقديمها لخدماتها.`,

  // [2.] Aamer Logistics
  aamer_logistics_description: `آمر اللوجستية شركة رائدة في تقديم الحلول اللوجستية لخدمات الإيصال الفعّال للوثائق بشكل يلبّي متطلبات جميع العملاء من الأفراد والشركات. عملت الشركة على بناء شراكات استراتيجيةٍ مع الوزارات والجهات الحكومية وشبه الحكومية والشركات لإنشاء منصةٍ موحدةٍ تسمح بالإيصال السلس لمختلف أنواع الوثائق ومواكبة رؤى حكومة دولة الإمارات العربية المتحدة وتطلعاتها وضمان سعادة العملاء والإيصال السريع وذو الجودة العالية للوثائق.`,

  // [3.] Aamer Business
  aamer_business_description: `آمر للأعمال هي شركةٌ أعمال متخصصة في تقديم الخدمات الإدارية لرجال الأعمال. وتضم مرافق الأعمال التي توفر خدمات السكرتارية والإدارة اللازمة `,

  // [4.] Aamer Delivery
  aamer_delivery_description: `آمر للتوصيل هي مركزٍ متعدد الخدمات متخصص في استلام وتسليم المستندات الخالصة للشركات والأفراد على حدٍ سواء، وتهدف آمر للتوصيل إلى تقديم أفضل الحلول والطرق لإنجاز وتخليص واستلام وتسليم المستندات الحكومية بالنيابة عن العملاء من أجل توفير الوقت باستخدام إجراءٍ سهلٍ يسمح للعملاء بتقديم وثائقهم حيثما وجدوا. وتركز الشركة على الاستراتيجيات التالية: ضمان راحة العملاء وسعادتهم، والسرعة وتوفير الوقت والجهد، والخصوصية وضمان سرية معلومات العملاء باستخدام نظام آمن.`,

  // [4.] Aamer Digital Hub
  aamer_digitalHub_description: `تُعد منصة آمر الرقمية شركة بارزة في مجال تقنية المعلومات حيث تطوّر حلول للبرمجيات المتقدمة والمنصات الرقمية مستهدفة بذلك مختلف الأسواق. أنشأت الشركة منصة تطبيق آمر وبوابة خدمات ذكية ومركز اتصال تشغيلي يجمع بين القطاعين الحكومي والخاص من خلال خدماتها النوعية المتميزة للعملاء. تهدف حلولها الآنية ذات القيمة المضافة إلى توفير خاصية فعالة وشاملة من خلال منصة آمنة.`,
};
