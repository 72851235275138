export const companySlideDetails = [
  {
    id: "aamer_consultancy",
    logo: require("assets/icons/common/companies/aamer-consultancy.png"),
    sideImage: require("assets/images/pages/our-companies/consultancy.jpg"),
    description: "aamer_consultancy_description",
  },
  {
    id: "aamer_logistics",
    logo: require("assets/icons/common/companies/aamer-logistics.png"),
    sideImage: require("assets/images/pages/our-companies/logistics.jpg"),
    description: "aamer_logistics_description",
  },
  {
    id: "aamer_business",
    logo: require("assets/icons/common/companies/aamer-business.png"),
    sideImage: require("assets/images/pages/our-companies/business.jpg"),
    description: "aamer_business_description",
  },
  {
    id: "aamer_delivery",
    logo: require("assets/icons/common/companies/aamer-delivery.png"),
    sideImage: require("assets/images/pages/our-companies/delivery.jpg"),
    description: "aamer_delivery_description",
  },
  {
    id: "aamer_digital_hub",
    logo: require("assets/icons/common/companies/aamer-digital-hub.png"),
    sideImage: require("assets/images/pages/our-companies/digital-hub.jpg"),
    description: "aamer_digitalHub_description",
  },
];
