export const home_AR = {
  // [1.] About Us
  hero_aboutUs_heading: `مجموعة آمر`,
  hero_aboutUs_description: `تقدم مجموعة آمر الخدمات الإدارية للأعمال ، وترجمة المستندات والتصديق ، واستلام المستندات وتسليمها ، والإقامة الذهبية ، والهجرة والهوية ، والإدارة القضائية في الإمارات العربية المتحدة`,
  hero_aboutUs_buttonText: `المزيد عنا`,

  // [2.] Service
  services_heading: `الخدمات`,
  services_description: `نقدم الخدمات التالية:`,

  // [3.] Our Companies
  our_companies_heading: `شركاتنا`,
  learn_more: `التعرف على المزيد`,

  // [4.] Highlights
  highlights_slide_learnMore: `التعرف على المزيد`,

  // [5.] Testimonials
  testimonials_heading: `ماهو رأي العملاء بخدماتنا ؟`,
  testimonials_description: `نحن نقدر رأي عملائنا بنا، ما يجعلنا أقرب لطلباتك العملاء. رأي العملاء هو الوسيلة لتطوير أعمالنا وتلبية احتياجتهم`,

  // [6.] Portal
  portal_heading: `بوابة آمر`,
  portal_description: `آمر اللوجستية هي شركة رائدة في تقديم الحلول اللوجستية المبسطة والمخصصة للتسليم الفعال للمعاملات والمستندات التي تلبي متطلبات جميع المتعاملين من الأفراد والشركات.`,
};
