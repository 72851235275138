export const iconsPath = {
  // common
  LOGO_RED_WITHTEXT: require("assets/icons/common/logo-red-withText.png"),
  LOGO_RED_WITHOUTTEXT: require("assets/icons/common/logo-red-withoutText.png"),
  LOGO_WHITE_WITHOUTTEXT: require("assets/icons/common/logo-white-withoutText.png"),
  APP_STORE: require("assets/icons/common/app-store.png"),
  PLAY_STORE: require("assets/icons/common/play-store.png"),
  RIGHT_ARROW: require("assets/icons/common/right-arrow.png"),

  // pages > navbar
  MENU: require("assets/icons/pages/navbar/menu.png"),
  CLOSE: require("assets/icons/pages/navbar/close.png"),

  // pages > footer
  WEBSITE: require("assets/icons/pages/footer/website.png"),
  MAIL: require("assets/icons/pages/footer/mail.png"),
  PHONE: require("assets/icons/pages/footer/phone.png"),
  FACEBOOK: require("assets/icons/pages/footer/facebook.png"),
  INSTAGRAM: require("assets/icons/pages/footer/instagram.png"),
  LINKEDIN: require("assets/icons/pages/footer/linkedin.png"),
  SNAPCHAT: require("assets/icons/pages/footer/snapchat.png"),
  TWITTER: require("assets/icons/pages/footer/twitter.png"),

  // pages > our partners
  EMIRATES_POST: require("assets/icons/pages/our-partners/emirates-post.png"),

  // pages > golden visa
  GOLDEN_VISA_SECTION2: require("assets/icons/pages/golden-visa/section2.png"),
};
