export const privacyPolicySlideDetails_EN = {
  // slide1
  privacyPolicy_slide1_heading: `Gathering information`,
  privacyPolicy_slide1_shortDescription: `In the next section, we describe the information we collect from you and the basic purposes of collecting that information.`,
  privacyPolicy_slide1_longDescription: `We will not disclose or distribute such information to any third party for any purpose other than those described herein without your prior consent and will notify you in advance by email of any disclosure or distribution so that you have the right to opt-out, consent to disclosure, or distribution as detailed below. To provide our services to you, we will use your IP address to help diagnose problems with our web server and manage our domain. Your IP address is used to help identify you, track activity within our site, and gather extensive demographic information. We collect IP information with our web server. Our site uses cookies to track your session ID. We may use cookies to provide content specific to your interests. We recommend that you review our cookies policy.

Our registration and application form require users to provide us with contact information (such as name and email address), financial information (such as account or credit card numbers), and demographic information (such as age or income level). The user's contact information is used to contact the user if necessary. Users may opt-out of receiving future emails as described in the selection/unsubscribe section below. The financial information collected is used to verify the user's credentials and qualifications for registration and to invoice the user for products and services.

Demographic data and profiles are also collected on our site. We use this data to customize the user experience on our website, show services and content we believe may be of interest, and display content and services based on their preferences.
`,

  // slide2
  privacyPolicy_slide2_heading: `Upgrades`,
  privacyPolicy_slide2_shortDescription: `Aamer Digital Hub Platform may offer some promotions through their site or application. If you choose any of these offers, we may ask you to provide personally identifiable information.`,
  privacyPolicy_slide2_longDescription: `In such cases, the digital ordering platform may use the information provided to contact you in connection with the promotional event, for other marketing purposes, or for other reasons, including sharing information with third-party companies. Therefore, if you do not wish to provide personally identifiable information, Aamer Digital Hub Platform recommends that you not participate in any promotional events on its website.`,

  // slide3
  privacyPolicy_slide3_heading: `Using the Aamer Digital Hub Platform site`,
  privacyPolicy_slide3_shortDescription: `The Aamer Digital Hub Platform site is built for you, and the Aamer Digital Hub Platform understands that the needs of its users and customers vary.`,
  privacyPolicy_slide3_longDescription: `The Aamer Digital Hub Platform site will do its best to offer the services and features that suit the needs of its customers and visitors.`,

  // slide4
  privacyPolicy_slide4_heading: `Choice/withdrawal`,
  privacyPolicy_slide4_shortDescription: `You can send mail to info@aamerhub.ae

For complete contact details, including emails and phone numbers, please visit our Contact US page.`,
  privacyPolicy_slide4_longDescription: `Our Aamer Digital Hub Platform provides users with the opportunity to opt-out of receiving communications from the Aamer Digital Hub Platform website, the mobile site, and/or the app at the point where we request user information, and the Aamer Digital Hub Platform allows users to remove their information from our database to receive no more communications or not receive our services after the date information was removed.`,

  // slide5
  privacyPolicy_slide5_heading: `Access and accuracy of data`,
  privacyPolicy_slide5_shortDescription: `While all information may be considered correct and accurate at the time of publication, this does not mean that the Aamer Digital Hub Platform assumes any liability or responsibility for the accuracy, completeness, or usefulness of any data, information, or material listed on or accessible from this site. `,
  privacyPolicy_slide5_longDescription: `Neither we nor any third party provide any warranty or insurance with respect to the accuracy, timeliness, performance, completeness, or appropriateness of the information and materials on this site or provided for any particular purpose. You acknowledge that this information and materials may contain inaccurate information or errors, and you acknowledge that the Aamer Digital Hub Platform is not liable for any errors or inaccuracies of this kind to the maximum extent permitted by law.

Any advice or information provided by the Aamer Digital Hub Platform or its affiliates is such as making it easier for the user and obliging the user to make sure that it is correct, accurate, and appropriate to him or her. Information or data may change without notice, and in no event shall the Aamer Digital Hub Platform or any of its affiliates be liable for the accuracy or validity of any printed or stored information or in any manner whatsoever, which is interpreted and used by the user. The user acknowledges that in no event shall the Aamer Digital Hub Platform and its affiliates be liable for any compensatory, special, direct, INCIDENTAL, INDIRECT, CONSEQUENTIAL, exemplary DAMAGES, or any DAMAGES of any kind resulting from the change, inaccuracy, or loss of use of data, information, or profits arising out of or in connection with the use or performance of the data, information, or materials listed on this site, the application of Aamer Digital Hub Platform, the Internet in general, or on any other basis.
`,

  // slide6
  privacyPolicy_slide6_heading: `Other Parties`,
  privacyPolicy_slide6_shortDescription: `Aamer Digital Hub Platform is not responsible for inaccuracies in data provided by any third parties.`,
  privacyPolicy_slide6_longDescription: `This Aamer Digital Hub Platform site may contain links to third-party sites that are not controlled by the digital AAMER platform. Where such links are provided for the user's convenience, the user acknowledges and agrees that the use of such links is at their own risk. We also emphasize that the Aamer Digital Hub Platform has no control over any sites, content, or services provided by any third party or its affiliates, and the user acknowledges that the Aamer Digital Hub Platform and any of its affiliates are not in any way responsible for such sites or content or the services they provide. The Aamer Digital Hub Platform and any of its affiliates are not responsible for the privacy policy or any third-party site practices that provide a link-order platform on their site.`,

  // slide7
  privacyPolicy_slide7_heading: `Data security`,
  privacyPolicy_slide7_shortDescription: `We will ensure that appropriate security measures are taken against illegal or unauthorized processing of personal data and against accidental loss or damage to personal data.`,
  privacyPolicy_slide7_longDescription: `The transfer of information between our website and your device is protected by Transport Layer Security certificates (TLS). When the website is accessed using compatible browsers, this technology protects personal information using server authentication and data encryption to ensure personal information is secure during transit. The major browser versions compatible with this technology are Internet Explorer (version 11), Mozilla Firefox (version 27), Google Chrome (version 32), and Apple Safari (version 7).

All personal data is stored in a secure server environment that uses a firewall and other advanced technology to protect against interference or unauthorized access. However, due to the Internet's situation and its long-haul, the user acknowledges that there is no secure 100% data transfer method, and absolute security cannot be guaranteed.`,

  // slide8
  privacyPolicy_slide8_heading: `Data storage`,
  privacyPolicy_slide8_shortDescription: `We will only store your data as long as it is absolutely necessary for the purposes for which it is collected (i.e., to provide you with our services or to meet any legal, accounting, or reporting requirements). `,
  privacyPolicy_slide8_longDescription: `In any event, data retention will not exceed 10 years from the date of termination or completion of the Services. This retention period allows us to use the data to defend potential legal claims, taking into account applicable limitation periods under relevant laws, and, if applicable, to comply with AML or KYC laws and regulations, and to combat bribery or anti-corruption laws and regulations, and the accounting and tax laws applicable in accordance with the jurisdictions in which we operate.`,

  // slide9
  privacyPolicy_slide9_heading: `Aamer App Account Deletion and Data Storage`,
  privacyPolicy_slide9_shortDescription: `If you no longer wish to use Aamer App content and services, you can delete your account and the associated account information.`,
  privacyPolicy_slide9_longDescription: `However, your data will be stored with Aamer as long as it is absolutely necessary for the purposes for which it is collected (i.e., to provide you with our services or to meet any legal, accounting, or reporting requirements). In any event, data retention will not exceed 10 years from the date of termination or completion of the Services. This retention period allows us to use the data to defend potential legal claims, taking into account applicable limitation periods under relevant laws, and, if applicable, to comply with AML or KYC laws and regulations, and to combat bribery or anti-corruption laws and regulations, and the accounting and tax laws applicable in accordance with the jurisdictions in which we operate.
`,

  // slide10
  privacyPolicy_slide10_heading: `Data reduction`,
  privacyPolicy_slide10_shortDescription: `Whenever possible and as far as possible`,
  privacyPolicy_slide10_longDescription: `We hide the identity of the data we hold about you when it is not necessary to identify you from that data.`,

  // slide11
  privacyPolicy_slide11_heading: `Modifications to the Privacy Policy`,
  privacyPolicy_slide11_shortDescription: `Any concerns about this Policy or disputes arising out of or relating to this Policy will be subject to the terms and conditions herein.

Please check back frequently to see any updates or changes to this privacy policy.`,
  privacyPolicy_slide11_longDescription: `Aamer Digital Hub Platform reserves the right to make additions, deletions, or modifications, in whole or in part, to the Privacy Policy, and as needed to change its policy all such changes will be made to the Website and an update will be made on the effective date to reflect the date of the changes. `,
};
