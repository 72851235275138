export const disclaimer_EN = {
  disclaimer: `disclaimer`,
  disclaimer_description: `This Aamer Digital Hub Platform is limited to providing the services described on the Site only and does not include any offer, request, advice, or recommendation made by this Site or its services in connection with any product, service, investment service, offer to sell, promote, or induce the purchase of any property or any product, commodity, securities, or warranty. Any information or declarations made on the website regarding any of the foregoing are considered public information or provided by a third party solely responsible for its information or declarations.
  
  Notwithstanding our efforts to verify the authenticity of the information and/or declarations made on the Site by any third party, this does not guarantee the accuracy of such information or confirm its validity. Therefore, the platform does not bear any liability whatsoever for any of the information and/or advertisements contained on this website. Consequently, the Aamer Digital Hub Platform recommends that you obtain independent professional advice before making any decision or investment and acknowledges that your choices and actions remain your own responsibility without any liability to the Aamer Digital Hub Platform.`,
  disclaimer_middleLeftDescription: `The Aamer Digital Hub Platform asserts that the material on this site includes third-party advertisements for which the author/advertiser is fully responsible, or it comprises public information that does not constitute legal, technical, or professional advice in any form. The Aamer Digital Hub Platform is not liable for any damage or loss arising from relying on such advertisements or information regarding any offer, request, advice, or recommendation for any product, service, investment service, offer to sell, promote, or induce the purchase of any property, product, commodity, securities, or warranty of any kind. The platform does not warrant that the digital ordering platform is expressly or implicitly correct or guaranteed to be available in any region of the world.
`,
  disclaimer_middleRightDescription: `The user acknowledges that they fully understand that the Aamer Digital Hub Platform, any of their affiliates, and/or any of their employees are not responsible for the validity or completeness of any of the information or advertisements indicated. Any of the advertisements or information on the site may be extended, changed, or deleted in part or in whole by the author/advertiser without a separate ad, and the Aamer Digital Hub Platform is not responsible for this. The user's acceptance of the platform's services listed exclusively on the site constitutes an express acknowledgment and understanding that the Aamer Digital Hub Platform holds no responsibility for any information or advertisements on the site, and the user will not pursue any legal actions against the platform for any of the advertisements or information referred to.
  
The Aamer Digital Hub Platform updates the advertisements or information on the site based on the requirements of their source, whether the author/advertiser. The integration of information or advertising links provided by the author/advertiser on the page is an additional service to site users or readers. Neither the digital AAMER platform nor any of its employees have been involved in the creation of such links or content on which they are contained. They are not held liable for any content of any such link, and users are required to use any such information or advertising at their own risk. Any questions, comments, or information from the copyright/advertiser's source of the link do not hold any liability on the Aamer Digital Hub Platform, its affiliates, and/or any of its employees for any such information or advertising. The platform shall not be liable for any loss to any person regardless of the cause or origin of any action or omission from reliance on the contents of the sites to which any of the information or advertising links lead.
`,
  disclaimer_bottomDescription: `The information provided on this site by the Aamer Digital Hub Platform is not intended for distribution or use by any person or entity in any jurisdiction or country where such distribution or use is contrary to law or regulation or would display the Aamer Digital Hub Platform and/or any of its affiliates and/or any of its employees having any legal liability of any kind within this jurisdiction or state.`,
};
