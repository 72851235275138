export const commonContactUs_EN = {
  contactUs_heading: `contact us`,

  // Form
  contactUs_form_name: `name`,
  contactUs_form_name_placeholder: `Enter your full name`,
  contactUs_form_email: `email`,
  contactUs_form_email_placeholder: `Enter your email address`,
  contactUs_form_phone: "phone",
  contactUs_form_phone_placeholder: `Enter your phone number`,
  contactUs_form_dropDown: `subject`,
  contactUs_form_dropDown_placeholder: `choose`,
  contactUs_form_dropDown_option_goldenVisa: `golden visa`,
  contactUs_form_dropDown_option_translationAndAttestation: `translation and attestation`,
  contactUs_form_dropDown_option_immigrationAndId: `immigration and ID`,
  contactUs_form_dropDown_option_logistics: `logistics`,
  contactUs_form_dropDown_option_technical: `technical`,
  contactUs_form_dropDown_option_csp: `CSP`,
  contactUs_form_dropDown_option_feedbackAndComplaint: `feedback & complaints`,
  contactUs_form_message: `message`,
  contactUs_form_message_placeholder: `Explain your query here...`,

  contactUs_form_buttonText: `send message`,

  // Contact Details
  contactUs_address: `address`,
  contactUs_address_details: `AAMER GROUP, PO BOX : 2913, Abu Dhabi, United Arab Emirates`,
  contactUs_phone: `phone`,
  contactUs_phone_details: `+97126519000`,
  contactUs_email: `email address`,
  contactUs_website: `website`,
};
