export const privacyPolicySlideDetails_AR = {
  // slide1
  privacyPolicy_slide1_heading: `جمع المعلومات`,
  privacyPolicy_slide1_shortDescription: `في القسم التالي، نقوم بوصف المعلومات التي نجمعها منك والأغراض الأساسية لجمع تلك المعلومات.`,
  privacyPolicy_slide1_longDescription: `ولن نكشف هذه المعلومات أو ننشرها لأي طرف ثالث لأي غرض آخر بخلاف ذلك المذكور في هذا البيان، دون موافقتك المسبقة وسوف نخطرك مسبقاً عن طريق البريد الإلكتروني عن أي إفشاء أو توزيع بحيث يكون لك الحق في الانسحاب أو الموافقة على الكشف أو التوزيع كما هو مفصل أدناه، ولتقديم خدماتنا لك، سنستخدم عنوان بروتوكول الإنترنت الخاص بك للمساعدة في تحديد المشكلات مع خادم الويب الخاص بنا وإدارة مجالنا، ويُستخدم عنوان بروتوكول الإنترنت الخاص بك للمساعدة في التعرف عليك وتتبع نشاطك داخل موقعنا وجمع معلومات ديموغرافية شاملة، كما سنجمع معلومات بروتوكول الإنترنت على خادم الويب الخاص بنا. ويستخدم موقعنا ملفات تعريف الارتباط لتتبع معّرف الجلسة الخاصة بك، كما قد نستخدم ملفات تعريف الارتباط لتوفير محتوى ذي صلة باهتماماتك، ونوصيك بمراجعة سياسة ملفات تعريف الارتباط الخاصة بنا.


يتطلب نموذج التسجيل والطلب الخاص بنا من المستخدمين تزويدنا بمعلومات الاتصال (مثل الاسم وعنوان البريد الإلكتروني، والمعلومات المالية  (مثل أرقام الحساب أو بطاقة الائتمان)، والمعلومات الديموغرافية (مثل العمر أو مستوى الدخل) وتُستخدم معلومات اتصال المستخدم للاتصال بالمستخدم إذا لزم الأمر، يمكن للمستخدمين إلغاء الاشتراك في خدمة تلقي رسائل البريد الإلكتروني المستقبلية كما هو موضح في قسم الاختيار/إلغاء الاشتراك أدناه، وتُستخدم المعلومات المالية التي جُمعت للتحقق من بيانات اعتماد المستخدم ومؤهلاته للتسجيل ولإصدار فاتورة للمستخدم مقابل المنتجات والخدمات.


 تُجمع أيضًا البيانات الديموغرافية وبيانات ملف التعريف على موقعنا، ونستخدم هذه البيانات لتخصيص تجربة المستخدم على موقعنا الإلكتروني وعرض الخدمات والمحتوى الذي نعتقد أنك قد تكون مهتمًا به وعرض المحتوى والخدمات بناءً على تفضيلاتك
`,

  // slide2
  privacyPolicy_slide2_heading: `الترقيات`,
  privacyPolicy_slide2_shortDescription: `قد تقدم منصة آمر الرقمية بعض العروض الترويجية على موقعها أو تطبيقها؛ فإذا اخترت أيًا من هذه العروض، قد نطلب منك تقديم معلومات تعريف شخصية.`,
  privacyPolicy_slide2_longDescription: `وفي هذه الحالة، قد تستخدم منصة الطلبات الرقمية المعلومات المقدمة للاتصال بك فيما يتعلق بفعالية الترويج، أو لأغراض تسويقية أخرى، أو لأسباب أخرى ، بما في ذلك مشاركة المعلومات مع شركات خارجية، لذلك، إذا كنت لا ترغب في توفير معلومات التعريف الشخصية، توصي منصة آمر الرقمية بعدم المشاركة في أي فعاليات ترويجية على موقعها الإلكتروني أو `,

  // slide3
  privacyPolicy_slide3_heading: `استخدام موقع منصة آمـر الرقمـية`,
  privacyPolicy_slide3_shortDescription: `بُنيت منصة آمر الرقمية لأجلك، وتعي المنصة أن احتياجات مستخدميها وعملائها تختلف من مستخدم/عميل لآخر`,
  privacyPolicy_slide3_longDescription: `كما ستبذل منصة آمر الرقمية قصارى جهدها لتقديم الخدمات والخصائص التي تتلاءم مع احتياجات عملائها وزوارها`,

  // slide4
  privacyPolicy_slide4_heading: `الاختيار/الانسحاب`,
  privacyPolicy_slide4_shortDescription: `يمكنك إرسال بريد إلى العنوان info@aamerhub.ae

للحصول على تفاصيل الاتصال الكاملة بما في ذلك رسائل البريد الإلكتروني وأرقام الهواتف، يرجى زيارة صفحة "اتصل بنا".`,
  privacyPolicy_slide4_longDescription: `توفر منصة آمر الرقمية للمستخدمين فرصة إلغاء الاشتراك في تلقي الاتصالات من منصة آمر الرقمية، وموقع الهاتف المحمول و/ أو التطبيق، عند النقطة التي نطلب فيها معلومات المستخدم، وتتيح منصة آمر الرقمية للمستخدمين إزالة معلوماتهم من قاعدة بياناتنا لعدم تلقى المزيد من الاتصالات أو عدم تلقي خدماتنا بعد تاريخ إزالة المعلومـات.`,

  // slide5
  privacyPolicy_slide5_heading: `الوصول إلى البيانات ودقتها`,
  privacyPolicy_slide5_shortDescription: `في حين أن جميع المعلومات قد تعتبر صحيحة ودقيقة في وقت النشر، إلا أن هذا يعني أن منصة آمر الرقمية تتحمل أي مسؤولية عن دقة أو اكتمال أو نفعية أي بيانات أو معلومات أو مواد مدرجة على هذا الموقع أو يمكن الوصول إليها من خلاله .`,
  privacyPolicy_slide5_longDescription: `ولا نقدم نحن أو أي طرف ثالث أي ضمان أو تأمين فيما يتعلق بدقة المعلومات والمواد الموجودة على هذا الموقع أو حسن توقيتها أو أدائها أو اكتمالها أو ملاءمتها أو ماهية الأغراض المقدمة لأجلها. كما يقر المستخدم بأن هذه المعلومات والمواد قد تحتوي على معلومات غير دقيقة أو أخطاء، ويقر بأن منصة آمر الرقمية ليست مسؤولة عن أي أخطاء أو عدم دقة من هذا النوع إلى أقصى حد يسمح به القانون.


تعد أي نصائح أو معلومات تقدمها منصة آمر الرقمية أو الشركات التابعة لها بمثابة تسهيل على المستخدم ويلتزم المستخدم بالتأكد من صحتها ودقتها ومناسبتها له، وقد تتغير المعلومات أو البيانات دون إشعار ولن تتحمل منصة آمر الرقمية أو أي من شركاتها التابعة بأي حال من الأحوال مسؤولية دقة أي معلومات مطبوعة أو مخزنة بأي طريقة كانت أو صحتها، والتي يفسرها ويستخدمها المستخدم، ويقر المستخدم بأن منصة آمر الرقمية والشركات التابعة لها لن تتحمل بأي حال من الأحوال المسؤولية عن أي أضرار تعويضية أو خاصة أو مباشرة أو عرضية أو غير مباشرة أو تبعية أو نموذجية أو أي أضرار من أي نوع ناتجة عن التغيير أو عدم الدقة أو فقدان بيانات الاستخدام أو المعلومات أو الأرباح الناشئة عن استخدام البيانات أو أدائها أو المعلومات أو المواد المدرجة في هذا الموقع أو تطبيق منصة آمر الرقمية أو الإنترنت بشكل عام أو على أي أساس آخر أو فيما يتعلق بذلك.`,

  // slide6
  privacyPolicy_slide6_heading: `الأطراف الأخرى`,
  privacyPolicy_slide6_shortDescription: `لاتُعد منصة آمر الرقمـيـة مسؤولة عن عدم دقة البيانات التي يقدمها أي طرف.`,
  privacyPolicy_slide6_longDescription: `ثالث، وقد يحتوي موقع منصة آمر الرقمية هذا على روابط لمواقع خاصة بأطراف ثالثة لا تتحكم فيها منصة آمر الرقمية حيث يتم توفير هذه الروابط لراحة المستخدم، ويقر المستخدم ويوافق على أن استخدام هذه الروابط سيكون على مسؤوليته الخاصة، كما نؤكد أيضًا أن منصة آمر الرقمية أو الشركات التابعة لها لا تتحكم في أي مواقع أو محتوى أو خدمات تقدمها أي أطراف ثالثة أو شركات تابعة لها، ويقر المستخدم بأن منصة آمر الرقمية وأي من الشركات التابعة لها ليست مسؤولة بأي شكل من الأشكال عن هذه المواقع أو المحتوى أو الخدمات التي تقدمها، ولا تكون منصة آمر الرقمية وأي من الشركات التابعة لها مسؤولة عن سياسة الخصوصية أو أي ممارسات لموقع الطرف الثالث توفر منصة ترتيب الارتباط على موقعها.`,

  // slide7
  privacyPolicy_slide7_heading: `أمن البيانات`,
  privacyPolicy_slide7_shortDescription: `سنضمن اتخاذ الإجراءات الأمنية المناسبة ضد المعالجة غير القانونية أو غير المصرح بها للبيانات الشخصية وضد الفقد أو التلف العرضي للبيانات الشخصية.`,
  privacyPolicy_slide7_longDescription: `تُعد عملية نقل المعلومات بين موقعنا الإلكتروني وجهازك محمية بشهادات أمان طبقة النقل، وعند الوصول إلى موقع الويب باستخدام متصفحات متوافقة، تحمي هذه التقنية المعلومات الشخصية باستخدام مصادقة الخادم وتشفير البيانات لضمان تأمين المعلومات الشخصية أثناء النقل، وتعد إصدارات المستعرض الرئيسية المتوافقة مع هذه التقنية هي Internet Explorer  الإصدار 11 و Mozilla Firefox  الإصدار 27  و Google Chrome  الإصدار 32  وApple Safari  الإصدار7.

تُخزن جميع البيانات الشخصية في بيئة خادم آمنة تستخدم جدار حماية وغيرها من التقنيات المتقدمة للحماية من التداخل أو الوصول غير المصرح به. إلا أنه نظرًا لحالة الإنترنت وطول أمدها، يقر المستخدم بأنه لا توجد طريقة آمنة بنسبة 100٪ لنقل البيانات، ولا يمكن ضمان الأمان المطلق.`,

  // slide8
  privacyPolicy_slide8_heading: `تخزين البيانات`,
  privacyPolicy_slide8_shortDescription: `سنقوم بتخزين بياناتك فقط طالما كانت ضرورية للغايات والأغراض التي تم جمعها من أجلها (على سبيل المثال، لتزويدك بخدماتنا أو لتلبية أي متطلبات قانونية أو محاسبية أو إعداد تقارير) وفي كل الأحوال، لن يتجاوز الاحتفاظ بالبيانات مدة 10 سنوات من تاريخ إنهاء أو إكمال الخدمات.`,
  privacyPolicy_slide8_longDescription: `تسمح لنا فترة الاحتفاظ هذه باستخدام البيانات للمرافعة في المطالبات القانونية المحتملة، مع مراعاة فترات التقادم المعمول بها بموجب القوانين ذات الصلة، وإذا كان ذلك ممكناً ، للامتثال لقوانين ولوائح مكافحة غسيل الأموال أو اعرف عميل ، ولمكافحة الرشوة أو قوانين مكافحة الفساد و اللوائح والقوانين المحاسبية والضريبية المعمول بها وفقاً للسلطات القضائية التي نعمل ضمن نطاقها`,

  // slide9
  privacyPolicy_slide9_heading: `حذف الحساب في تطبيق أمــر وتخزين البيانات`,
  privacyPolicy_slide9_shortDescription: `في حال عدم رغبتك باستخدام محتويات تطبيق آمـر وخدمات التطبيق، يمكنك حذف حسابك والمعلومات المرتبطة به. وفي جميع الحالات سنقوم بتخزين بياناتك مع آمر فقط طالما كانت ضرورية للغايات والأغراض التي تم جمعها من أجلها (أي لتزويدك بخدماتنا أو لتلبية أي متطلبات قانونية أو محاسبية أو إعداد تقارير).`,
  privacyPolicy_slide9_longDescription: `في كل الأحوال، لن يتجاوز الاحتفاظ بالبيانات مدة 10 سنوات من تاريخ إنهاء أو إكمال الخدمات. تسمح لنا فترة الاحتفاظ هذه باستخدام البيانات للمرافعة في المطالبات القانونية المحتملة، مع مراعاة فترات التقادم المعمول بها بموجب القوانين ذات الصلة، وإذا كان ذلك ممكناً ، للامتثال لقوانين ولوائح مكافحة غسيل الأموال أو اعرف عميل ، ولمكافحة الرشوة أو قوانين مكافحة الفساد و اللوائح والقوانين المحاسبية والضريبية المعمول بها وفقاً للسلطات القضائية التي نعمل ضمن نطاقها.`,

  // slide10
  privacyPolicy_slide10_heading: `تقليل المعلومات`,
  privacyPolicy_slide10_shortDescription: `كلما كان ذلك ممكناً وبقدر المستطاع`,
  privacyPolicy_slide10_longDescription: `نخفي هوية البيانات التي نحتفظ بها عنك عندما لا يكون من الضروري التعرف عليك من خلال تلك البيانات.`,

  // slide11
  privacyPolicy_slide11_heading: `التعديلات على سياسة الخصوصية`,
  privacyPolicy_slide11_shortDescription: `وفي حال وجود أي مخاوف أو نزاعات نتيجـة هذه السياسة أو فيما يتعلق بها، تُطبق الشروط والأحكام الواردة في هذا البيان.
  
  يرجى المتابعة الدائمة لمعرفة أي تحديثات أو تغييرات تطرأ على سياسة الخصوصية هذه`,
  privacyPolicy_slide11_longDescription: `تحتفظ منصة آمر الرقمية بالحق في إجراء أعمال الإضافة أو الحذف أو التعديل، كلياً أو جزئياً، على سياسة الخصوصية ، وعند الحاجة لتغيير سياستها، ستُجرى جميع هذه التغييرات على الموقع الإلكترونـي ، كما سيُجرى تحديث على التاريخ الفعلي ليعكس تاريخ التغييرات.`,
};
