import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { iconsPath } from "utils/iconsPath";
import { imagesPath } from "utils/imagesPath";
import "./style.scss";

export const Footer = () => {
  // for translation
  const { t } = useTranslation("footer");

  return (
    <div
      className="Footer container"
      style={{ backgroundImage: `url(${imagesPath.BACKGROUND_PATTERN_LIGHT})` }}
    >
      <Link to="/">
        <img
          className="Footer_logo"
          src={iconsPath.LOGO_WHITE_WITHOUTTEXT}
          alt="aamer group"
        />
      </Link>

      <div className="Footer_links">
        <div className="Footer_separator" />
        <div className="Footer_link">
          <img src={iconsPath.WEBSITE} alt="website" />
          <Link to="/">www.aamergroup.ae</Link>
        </div>
        <div className="Footer_separator" />
        <div className="Footer_link">
          <img src={iconsPath.MAIL} alt="mail" />
          <Link to="mailto:info@aamergroup.ae">info@aamergroup.ae</Link>
        </div>
        <div className="Footer_separator" />
        <div className="Footer_link">
          <img src={iconsPath.PHONE} alt="mail" />
          <Link to="tel:+97126519000">{t("phone")}</Link>
        </div>
        <div className="Footer_separator" />
      </div>

      <div className="Footer_links">
        <Link to="/privacy-policy" className="Footer_link">
          {t("privacy_policy")}
        </Link>
        <Link to="/cookies-policy" className="Footer_link">
          {t("cookies_policy")}
        </Link>
        <Link to="/disclaimer" className="Footer_link">
          {t("disclaimer")}
        </Link>
        <Link to="/refund-policy" className="Footer_link">
          {t("refund_policy")}
        </Link>
      </div>

      <div className="Footer_bottom ">
        <div className="Footer_bottom_socialIcons ">
          <Link
            to="https://www.facebook.com/aamergroup.ae"
            target="_blank"
            rel="noreferrer"
          >
            <img src={iconsPath.FACEBOOK} alt="facebook" />
          </Link>
          <Link
            to="https://www.instagram.com/aamer_tm"
            target="_blank"
            rel="noreferrer"
          >
            <img src={iconsPath.INSTAGRAM} alt="instagram" />
          </Link>
          <Link
            to="https://twitter.com/aamer_tm"
            target="_blank"
            rel="noreferrer"
          >
            <img src={iconsPath.TWITTER} alt="twitter" />
          </Link>
          {/* <Link
            to="https://www.snapchat.com/aamergroup"
            target="_blank"
            rel="noreferrer"
          >
            <img src={iconsPath.SNAPCHAT} alt="snapchat" />
          </Link> */}
          <Link
            to="https://www.facebook.com/aamergroup.ae"
            target="_blank"
            rel="noreferrer"
          >
            <img src={iconsPath.LINKEDIN} alt="linkedin" />
          </Link>
        </div>
        <Link
          to="https://maps.google.com"
          target="_blank"
          rel="noreferrer"
          className="Footer_bottom_address "
        >
          {t("address")}
        </Link>
        <div className="Footer_bottom_copyright ">{t("copyright")}</div>
      </div>
    </div>
  );
};
