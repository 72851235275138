import { Application } from "./application";
import { CommonContactUs } from "../../common/contactUs";
import { Hero } from "./hero";
import { OurCompanies } from "./ourCompanies";
import { Services } from "./services";
import { Testimonials } from "./testimonials";
import useDocumentTitle from "utils/useDocumentTitle";
import toTitleCase from "utils/toTitleCase";
import { useTranslation } from "react-i18next";
import { Portal } from "./portal";

export const Home = () => {
  // for translation
  const { t } = useTranslation("home");

  // To change page title
  useDocumentTitle(toTitleCase(t("hero_aboutUs_heading")));

  return (
    <div className="Home">
      <Hero />
      <Services />
      <Portal />
      <OurCompanies />
      {/* <Highlight /> */}
      <Testimonials />
      <Application />
      <CommonContactUs />
    </div>
  );
};
