import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { iconsPath } from "utils/iconsPath";
import "./style.scss";

export const CommonModal = ({ isOpen, onClose, children }) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    if (isOpen) {
      document.body.classList.add("modal-open");
      document.addEventListener("keydown", handleKeyDown);
    } else {
      document.body.classList.remove("modal-open");
      document.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.body.classList.remove("modal-open");
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, onClose]);

  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      <div className="modal-overlay" onClick={onClose}></div>
      <div className="modal">
        <div className="modal_header_close" onClick={onClose}>
          <img src={iconsPath.CLOSE} alt="close" />
        </div>
        <div className="modal_content">{children}</div>
      </div>
    </>,
    document.getElementById("modal")
  );
};
