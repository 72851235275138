export const home_EN = {
  // [1.] About Us
  hero_aboutUs_heading: `aamer group`,
  hero_aboutUs_description: `Aamer Group offers Business Administrative Services, Documents Translation & Attestation, Documents Pick-up & Delivery, Golden Visa, Immigration & ID, Judicial Department in UAE`,
  hero_aboutUs_buttonText: `more about us`,

  // [2.] Services
  services_heading: `services`,
  services_description: `We offer the following services :`,

  // [3.] Our Companies
  our_companies_heading: `our companies`,
  learn_more: `learn more`,

  // [4.] Highlights
  highlights_slide_learnMore: `learn more`,

  // [5.] Testimonials
  testimonials_heading: `what clients say about our services`,
  testimonials_description: `We value what our clients have to say as it makes us stronger and more in touch with everyone’s needs. Customer feedback is vital in helping us to get it right.`,

  // [6.] Portal
  portal_heading: `aamer portal`,
  portal_description: `Aamer Logistics is a leading provider in simplified logistics solutions dedicated to efficient delivery of transactions and document that meet the requirement of all customers, both individuals and companies.`,
};
