export const navbar_EN = {
  home: `home`,
  about_us: `about us`,
  our_companies: `our companies`,
  our_partners: `our partners`,
  aamer_platform: `aamer platform`,
  blogs: `blogs`,
  contact_us: `contact us`,
  our_services: `our services`,
};
