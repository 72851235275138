import useDocumentTitle from "utils/useDocumentTitle";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { useWindowDimensions } from "utils/useWindowDimensions";
import { useState } from "react";
import { privacyPolicySlideDetails } from "data/privacyPolicySlideDetails";
import toTitleCase from "utils/toTitleCase";

export const PrivacyPolicy = () => {
  // for translation
  const { t } = useTranslation(["privacyPolicy", "privacyPolicySlideDetails"]);

  // To change page title
  useDocumentTitle(toTitleCase(t("privacyPolicy")));

  // To show and hide navigation arrows based on window width
  const { width } = useWindowDimensions();
  function toggleNavigation() {
    let navigation;
    if (width <= 1250) {
      navigation = false;
    } else {
      navigation = true;
    }
    return navigation;
  }

  // To enable auto height when swiper loads
  const [autoHeight, setAutoHeight] = useState(false);
  setTimeout(() => {
    setAutoHeight(true);
  }, 1000);

  return (
    <div className="PrivacyPolicy">
      <div className="PrivacyPolicy_header ">
        <div
          className="PrivacyPolicy_header_heading container"
          data-aos="fade-up"
        >
          <h2 className="PrivacyPolicy_header_heading_text">
            {t("privacyPolicy")}
          </h2>
          <div className="PrivacyPolicy_header_heading_underline"></div>
        </div>
        <h3
          className="PrivacyPolicy_header_description container"
          data-aos="fade-up"
        >
          {t("privacyPolicy_description")}
        </h3>
      </div>
      <div className="PrivacyPolicy_slider container">
        <swiper-container
          auto-height={{ autoHeight }}
          navigation={toggleNavigation()}
          pagination="true"
          pagination-clickable="true"
          pagination-dynamic-bullets="true"
          space-between="25"
          grab-cursor="true"
          autoplay-delay="5000"
          speed="1000"
          autoplay-disable-on-interaction="false"
        >
          {privacyPolicySlideDetails.map((item, index) => {
            return (
              <swiper-slide key={index}>
                <PrivacyPolicySlide
                  icon={item.icon}
                  heading={t("privacyPolicySlideDetails:" + item.heading)}
                  shortDescription={t(
                    "privacyPolicySlideDetails:" + item.shortDescription
                  )}
                  longDescription={t(
                    "privacyPolicySlideDetails:" + item.longDescription
                  )}
                />
              </swiper-slide>
            );
          })}
        </swiper-container>
      </div>
    </div>
  );
};

const PrivacyPolicySlide = ({
  icon,
  heading,
  shortDescription,
  longDescription,
}) => {
  return (
    <div className="PrivacyPolicySlide ">
      <div className="PrivacyPolicySlide_left ">
        <img
          className="PrivacyPolicySlide_left_icon"
          src={icon}
          alt="slide icon"
        />
        <h2 className="PrivacyPolicySlide_left_heading">{heading}</h2>
        <div className="PrivacyPolicySlide_left_underline"></div>
        <p className="PrivacyPolicySlide_left_description">
          {shortDescription}
        </p>
      </div>
      <div className="PrivacyPolicySlide_right ">
        <p className="PrivacyPolicySlide_right_description">
          {longDescription}
        </p>
      </div>
    </div>
  );
};
