export const ourPartners_AR = {
  ourPartners_heading: `شركاؤنا`,
  ourPartners_emiratesPost: `بريد الإمارات وإتحاد البريد العالمي`,

  ourPartners_description: `تعتبر " بريد الإمارات" المزود الرائد للخدمات البريدية على مستوى المنطقة، حيث توفر خيارات حلولاً مُبتكرة ومرنة في مجالات البريد والشحن داخل الدولة وحول العالم. وتعد الشركة أكبر مقدمي الخدمات البريدية داخل الدولة، ولديها ما يزيد عن 80 مركزاً لإسعاد المتعاملين، إلى جانب شبكة نقل وتوزيع متكاملة تغطي إمارات الدولة السبع. وتتيح "بريد الإمارات" حلولاً مؤتمتة وشاملة تدعم عمليات التجارة الإلكترونية وخدمات توصيل الميل الأخير.

"مجموعة بريد الإمارات" هي الجهة الرسمية المخولة بترخيص جميع الخدمات البريدية وخدمات التوصيل والخدمات اللوجستية داخل دولة الإمارات العربية المتحدة،  بموجب الآلية المتعبة في منصة آمـر، حيث إن شركة بريد الإمارات هي شركة مساهمـة عامة تزاول أنشطتها في جميع مناطق الدولة.

وتقدم مجموعة آمر بالتعاون مع مجموعة الإمارات حلولاً للبريد السريع والخدمات اللوجستية في دولة الإمارات العربية المتحدة. كمان أن بريد الإمارات عضو في اتحاد البريد العالمي (UPU)، وهو منظمة دولية تعمل على تنسيق السياسات البريدية بين الدول الأعضاء وتضمن التدفق السلس للبريد والطرود عبر الحدود. 
`,
};
